<template lang="pug">

  .video-component
    template(v-show="streamSrc")
      iframe.video-component__iframe(
        :src="streamSrc"
        frameborder="0" 
      ) 

</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'video-component',
  components: {},
  directives: {},
  filters: {},
  props: {
    streamSrc: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(['userData'])
  },
  watch: {},
  methods: {
    // ...mapActions("module", [
    //   "action1",
    //   "action2",
    // ])
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="scss">
@import '@/style/vars/vars.scss';

.video-component {
  position: relative;

  width: 100%;
  height: 0;
  padding-top: 56.25%;
  overflow: hidden;

  background-image: url('./img/placeholder.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 4px;

  &__iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

</style>
