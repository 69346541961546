<template lang="pug">

  div.placeholder
    

</template>

<script>
// import { mapActions, mapGetters } from "vuex";

export default {
  name: 'placeholder',
  components: {},
  directives: {},
  filters: {},
  props: {},
  data() {
    return {}
  },
  computed: {
    // ...mapGetters("module", [
    //   "getter1",
    //   "getter2",
    // ])
  },
  watch: {},
  methods: {
    // ...mapActions("module", [
    //   "action1",
    //   "action2",
    // ])
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.userData.id) {
        vm.$router.push({ name: 'Home' })
      } else {
        vm.$router.push({ name: 'Stream' })
      }
    })
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="scss">
@import '@/style/vars/vars.scss';

.placeholder {
  // position: fixed;
  // top: 0;
  // left: 0;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 40px);

  background-color: $primary;
  background-image: url('../assets/img/placeholder-mobile.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: $tablet) {
    background-image: url('../assets/img/placeholder.png');
    background-position: center center;
    background-size: 200% auto;
  }

  @media (min-width: $medium) {
    background-size: cover;
  }
}
</style>
