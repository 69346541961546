<template lang="pug">

  button.fixed-chat-button(
    :class="{active: active}"
  )
    .fixed-chat-button__icon
      v-icon(
        :icon="icon"
        :mask="false"
      )

</template>

<script>
// import { mapActions, mapGetters } from "vuex";
import VIcon from '@/components/common/VIcon/VIcon'

export default {
  name: 'fixed-chat-button',
  components: {
    VIcon
  },
  directives: {},
  filters: {},
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    // ...mapGetters("module", [
    //   "getter1",
    //   "getter2",
    // ])
    icon() {
      return this.active ? 'close-white.svg' : 'chat-white.svg'
    }
  },
  watch: {},
  methods: {
    // ...mapActions("module", [
    //   "action1",
    //   "action2",
    // ])
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="scss">
@import '@/style/vars/vars.scss';

.fixed-chat-button {
  display: flex;
  width: 60px;
  height: 60px;

  background-color: $secondary;
  border-radius: 50%;

  &__icon {
    width: 20px;
    height: 20px;
    margin: auto;

    color: transparent;
  }

  @media (min-width: $medium) {
    display: none;
  }
}
</style>
