import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'

import VueSvgIcon from 'vue-svgicon'
import VueSmoothScroll from 'vue2-smooth-scroll'

import 'normalize.css'
import '@/style/style.scss'

Vue.config.productionTip = false

/* webpack-strip-block:removed */

Vue.use(VueSvgIcon, {
  tagName: 'v-svg-icon'
})
Vue.use(VueSmoothScroll)

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')

