<template lang="pug">

  button.button-component(
    type="button"
    :class="[sizeModifier, colorModifier]"
  )
    slot

</template>

<script>
// import { mapActions, mapGetters } from "vuex";

export default {
  name: 'button-component',
  components: {},
  directives: {},
  filters: {},
  props: {
    size: {
      type: String,
      default: 'm',
      validator(value) {
        const sizes = ['xs', 's', 'm', 'l']
        return sizes.includes(value)
      }
    },
    color: {
      type: String,
      default: 'primary',
      validator(value) {
        const sizes = ['primary', 'secondary', 'on-dark', 'primary-blue']
        return sizes.includes(value)
      }
    }
  },
  data() {
    return {}
  },
  computed: {
    // ...mapGetters("module", [
    //   "getter1",
    //   "getter2",
    // ])
    sizeModifier() {
      return `${this.$options.name}_size_${this.size}`
    },
    colorModifier() {
      return `${this.$options.name}_color_${this.color}`
    }
  },
  watch: {},
  methods: {
    // ...mapActions("module", [
    //   "action1",
    //   "action2",
    // ])
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="scss">
@import '@/style/vars/vars.scss';

.button-component {
  $block: &;

  background: none;
  border: none;
  outline: none;

  display: flex;
  justify-content: center;
  align-items: center;

  letter-spacing: 0.04em;
  line-height: 1.25;

  border-radius: 4px;

  cursor: pointer;

  transition: 0.2s;

  &_size {
    &_xs {
      padding: 6px 15px 7px;

      font-size: 12px;
    }

    &_s {
      padding: 8px 20px 9px;

      font-size: 16px;
    }

    &_m {
      padding: 13px 25px 15px;

      font-size: 18px;
    }

    &_l {
      padding: 16px 30px 20px;

      font-size: 24px;
    }
  }

  &_color {
    &_primary {
      color: $white;
      background-color: $secondary;

      &:hover,
      &:focus {
        background-color: rgba($secondary, 0.8);
      }

      &:active {
        background-color: $secondary;
      }

      // &:disabled {
      //   background-color: $text-secondary;
      // }
    }

    &_primary-blue {
      color: $white;
      background-color: $blue;

      &:hover,
      &:focus {
        background-color: $primary;
      }

      &:active {
        background-color: $primary-dark;
      }

      &:disabled {
        background-color: $text-secondary;
      }
    }

    &_secondary {
      color: $primary;
      background-color: rgba($primary, 0.2);

      &:hover,
      &:focus {
        background-color: rgba($primary, 0.4);
      }

      &:active {
        background-color: rgba($primary, 0.6);
      }

      &:disabled {
        color: $white;
        background-color: $border-active;
      }
    }

    &_on-dark {
      color: $white;
      background-color: rgba($primary, 0.6);

      &:hover,
      &:focus {
        color: $primary;
        background-color: rgba($primary, 0.1);
      }

      &:active {
        color: $primary;
        background-color: rgba($primary, 0.2);
      }

      &:disabled {
        color: $white;
        background-color: $border-active;
      }
    }
  }
}
</style>
