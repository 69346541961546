<template lang="pug">

  .modal-cookie
    modal-layout(
      :no-overlay="true"
      @close-modal="$emit('close-modal')"
    )
      .modal-cookie__content
        h2.title.modal-cookie__title Файлы cookie
        p.modal-cookie__text Оставаясь на сайте, Вы соглашаетесь с использованием файлов cookie владельцем сайта.

        button-component.modal-cookie__btn(
          size="s"
          @click.native="$emit('close-modal')"
        ) Принять

</template>

<script>
// import { mapActions, mapGetters } from "vuex";
import ModalLayout from '@/components/layout/ModalLayout/ModalLayout'
import ButtonComponent from '@/components/UI/ButtonComponent/ButtonComponent'

export default {
  name: 'modal-cookie',
  components: {
    ModalLayout,
    ButtonComponent
  },
  directives: {},
  filters: {},
  props: {},
  data() {
    return {}
  },
  computed: {
    // ...mapGetters("module", [
    //   "getter1",
    //   "getter2",
    // ])
  },
  watch: {},
  methods: {
    // ...mapActions("module", [
    //   "action1",
    //   "action2",
    // ])
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="scss">
@import '@/style/vars/vars.scss';

.modal-cookie {
  .modal-layout {
    justify-content: flex-end;
    align-items: flex-end;
    width: min-content;
    height: min-content;
    max-width: 100%;
    max-height: 100vh;
    top: unset;
    left: unset;
    right: 0;
    bottom: 0;
  }

  &__content {
    width: 300px;
    max-width: 100%;
  }

  &__btn {
    margin-left: auto;
  }
}
</style>
