<template lang="pug">

  router-link.round-support-button(
    :to="{name: 'Support'}"
    :class="[supportBtnVisibillityModifier]"
  )
    .round-support-button__icon
      v-icon(
        icon="support.svg"
        :mask="false"
      )

</template>

<script>
// import { mapActions, mapGetters } from "vuex";
import VIcon from '@/components/common/VIcon/VIcon'

export default {
  name: 'round-support-button',
  components: {
    VIcon
  },
  directives: {},
  filters: {},
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    // ...mapGetters("module", [
    //   "getter1",
    //   "getter2",
    // ])
    supportBtnVisibillityModifier() {
      return this.$route.name === 'Support' ? 'hidden' : ''
    },
  },
  watch: {},
  methods: {
    // ...mapActions("module", [
    //   "action1",
    //   "action2",
    // ])
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="scss">
@import '@/style/vars/vars.scss';

.round-support-button {
  display: flex;
  width: 60px;
  height: 60px;

  background-color: #1D3C21;
  border-radius: 50%;

  &__icon {
    width: 20px;
    height: 20px;
    margin: auto;

    color: transparent;
  }
}
</style>
