<template lang="pug">

  .stream(ref="stream")
    main-page-layout(
      stream-src="https://live.proofix.tv/embed/tube.php?video=brookhp200521"

      :poll="currentPoll"
      :is-voting="isVoting"
      :is-results="isResultsShown"
      :winners="winners"
      :poll-is-shown="pollIsShown"
      :show-thanks="showThanks"

      @refresh-token="$emit('refresh-token')"
      @open-quiz="showFirstPoll"

      @start-poll-hiding-timer="startPollHidingTimer"
      @start-voting-hiding-timer="startVotingHidingTimer"
    )

</template>

<script>
import { mapState, mapActions } from 'vuex'
import axios from 'axios'
import MainPageLayout from '@/components/layout/MainPageLayout/MainPageLayout'

export default {
  name: 'stream',
  components: {
    MainPageLayout
  },
  directives: {},
  filters: {},
  props: {},
  data() {
    return {
      streamData: {},
      isVoting: false,
      lastPollId: null,
      currentPoll: {},
      pollHidingTimer: null,
      votingHidingTimer: null,
      pollIsShown: false,
      isResultsShown: true,
      winners: [
        {
          user: {
            id: 349,
            email: 'Сергей Масс'
          }
        },
        {
          user: {
            id: 380,
            email: 'Ксения Гущина'
          }
        },
        {
          user: {
            id: 154,
            email: 'Иван Андреев'
          }
        },
        {
          user: {
            id: 535,
            email: 'Александр Даниленко'
          }
        },
        {
          user: {
            id: 540,
            email: 'Игорь Бурса'
          }
        },
        {
          user: {
            id: 537,
            email: 'Вячеслав Милютин'
          }
        },
        {
          user: {
            id: 543,
            email: 'Андрей Еремин'
          }
        },
        {
          user: {
            id: 534,
            email: 'Сергей Бовсуновский'
          }
        },
        {
          user: {
            id: 389,
            email: 'Олеся Косточка'
          }
        },
        {
          user: {
            id: 298,
            email: 'Юдин (Диорит)'
          }
        },
        {
          user: {
            id: 193,
            email: 'Инна Лисакова'
          }
        },
      ],
      showThanks: false
    }
  },
  computed: {
    ...mapState([
      'userData',
      'chatIsOpen',
      'currentChat',
      'pollsList',
      'currentPollIndex'
    ])
  },
  watch: {},
  methods: {
    ...mapActions([
      'setCurrentChat',
      'toggleChat',
      'setChatIsOpenFlag',
      'setPollsList',
      'setCurrentPollIndex'
    ]),
    setCookie(key, value) {
      document.cookie = `${key}=${value};path=/;`
    },
    deleteCookie(cookieName) {
      let cookieDate = new Date() // Текущая дата и время
      cookieDate.setTime(cookieDate.getTime() - 1)
      document.cookie = cookieName += '=; expires=' + cookieDate.toGMTString()
    },
    getCookie(name) {
      let matches = document.cookie.match(
        // eslint-disable-next-line
        new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
      )
      return matches ? decodeURIComponent(matches[1]) : undefined
    },
    startFunction() {
      if (!this.userData.id) {
        // неавторизован, уходим на главную
        this.$router.push({ name: 'Home' })
        return
      }

      if (this.pollsList.length === 0) {
        this.getPollDataFromServer()
      }

      if (window.innerWidth >= 1240) {
        this.setChatIsOpenFlag(true)
      }
    },
    scrollToStream() {
      const stream = this.$refs.stream

      this.$smoothScroll({
        scrollTo: stream,
        updateHistory: false
      })
    },
    startPollHidingTimer() {
      // this.setCurrentPollIndex(this.currentPollIndex + 1)
      this.setCookie('done_poll_index', +this.getCookie('done_poll_index') + 1)

      this.pollHidingTimer = setTimeout(() => {
        this.showNextPoll()
      }, 1500)
    },
    startVotingHidingTimer() {
      this.votingHidingTimer = setTimeout(() => {
        this.isVoting = false
      }, 5000)
    },
    showVotingForm() {
      console.log('show voting start')
      // На всякий случай обнуляем таймаут закрытия от предыдущего голосования
      clearTimeout(this.votingHidingTimer)
      this.votingHidingTimer = null

      this.isVoting = true

      this.cancelFullscreen()
    },
    getPollDataFromServer() {
      axios
        // .get(`http://localhost:3004/poll-data/`, {
        .get(`https://live.proofix.tv/api-brooke-hotpoint/api/polls`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
            Accept: 'application/json'
          }
        })
        .then((response) => {
          if (response.data) {
            this.setPollsList(response.data)
          } else {
            console.log('Что-то пошло не так при получении списка опросов')
            console.log(response)
          }
        })
        .catch((error) => {
          console.error(error)
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$emit('refresh-token')
          }
        })
    },
    getQuizResults() {
      axios
        .get(`https://live.proofix.tv/api-brooke-hotpoint/api/polls/result`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
            Accept: 'application/json'
          }
        })
        .then((response) => {
          if (response.data.length > 0) {
            this.winners = response.data
            this.isResultsShown = true
          } else {
            console.log('Что-то пошло не так при получении результатоы квиза')
            console.log(response)
          }
        })
        .catch((error) => {
          console.error(error)
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$emit('refresh-token')
          }
        })
    },
    showPoll(pollData) {
      console.log('show poll start')
      // На всякий случай обнуляем таймаут закрытия от предыдущего квиза
      clearTimeout(this.pollHidingTimer)
      this.pollHidingTimer = null
      this.currentPoll = pollData

      this.cancelFullscreen()
    },
    showFirstPoll() {
      if (this.pollsList.length === 0) {
        console.log('Нет данных опроса')
        this.getPollDataFromServer()
        return
      }

      const currentPollIndex = +this.getCookie('done_poll_index')

      if (currentPollIndex === 0 && this.pollIsShown) {
        return //повтор
      }

      clearTimeout(this.pollHidingTimer) // На всякий случай чистим
      this.pollHidingTimer = null

      if (currentPollIndex > 0) {
        this.showNextPoll() //Уже не первый
        return
      }

      const poll = this.pollsList[0]
      this.showPoll(poll)
      this.pollIsShown = true
      // this.setCurrentPollIndex(0)
      this.setCookie('done_poll_index', 0)
    },
    showNextPoll() {
      const currentPollIndex = +this.getCookie('done_poll_index')

      clearTimeout(this.pollHidingTimer)
      this.pollHidingTimer = null

      if (currentPollIndex >= this.pollsList.length) {
        this.currentPoll = {} //Квиы закончились
        this.showThanks = true
        return
      }

      const poll = this.pollsList[currentPollIndex]
      this.showPoll(poll)
      this.pollIsShown = true
    },
    startStatisticPing() {
      if (!this.userData.id) {
        return
      }

      this.statisticInterval = setInterval(() => {
        axios
          // .get('http://localhost:3004/question')
          .post(
            'https://live.proofix.tv/api-brooke-hotpoint/api/online',
            {},
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                Accept: 'application/json'
              }
            }
          )
          .catch((error) => {
            console.log('Ошибка при пинг-запросе')
            console.error(error)
            console.error(error.response)

            if (error.response && error.response.status === 401) {
              this.$emit('refresh-token')
            }
          })
      }, 3000)
    },
    stopStatisticPing() {
      clearInterval(this.statisticInterval)
      this.statisticInterval = null
    },
    cancelFullscreen() {
      document.exitFullscreen
        ? document.exitFullscreen()
        : document.webkitCancelFullScreen
        ? document.webkitCancelFullScreen()
        : document.webkitExitFullscreen
        ? document.webkitExitFullscreen()
        : document.mozCancelFullScreen
        ? document.mozCancelFullScreen()
        : document.msExitFullscreen && document.msExitFullscreen()
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.userData.id) {
        vm.$router.push({ name: 'Home' })
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    this.setChatIsOpenFlag(false)
    next()
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    this.startFunction()
  },
  mounted() {
    this.startStatisticPing()

    this.setCurrentChat({
      widgetId: 106475,
      chatId: 'brookhp200521'
    })
  },
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {
    this.stopStatisticPing()
    clearTimeout(this.pollHidingTimer)
    this.pollHidingTimer = null
    clearTimeout(this.votingHidingTimer)
    this.votingHidingTimer = null
  },
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="scss">
@import '@/style/vars/vars.scss';

.stream {
  position: relative;
}
</style>
