<template lang="pug">

  .materials-link
    img.materials-link__img(src="./materials-bg.png")

    .materials-link__content 
      h3.title.materials-link__title Скачивайте полезные материалы
      p 
        | Пресс-релиз, фото и видео о новом
        br
        | холодильнике Hotpoint

      a.materials-link__button(href="/materials.zip" target="_blank")
        button-component Скачать

</template>

<script>
// import { mapActions, mapGetters } from "vuex";
import ButtonComponent from '@/components/UI/ButtonComponent/ButtonComponent'

export default {
  name: 'materials-link',
  components: {
    ButtonComponent
  },
  directives: {},
  filters: {},
  props: {},
  data() {
    return {}
  },
  computed: {
    // ...mapGetters("module", [
    //   "getter1",
    //   "getter2",
    // ])
  },
  watch: {},
  methods: {
    // ...mapActions("module", [
    //   "action1",
    //   "action2",
    // ])
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="scss">
@import '@/style/vars/vars.scss';

.materials-link {
  position: relative;

  border-radius: 4px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.04);

  color: $text-secondary;

  background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)),
    linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
    linear-gradient(0deg, #ffffff, #ffffff), url('./materials-bg.png');
  background-blend-mode: normal, overlay, hue, normal;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right center;

  height: 370px;

  &__content {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);

    width: calc(100% - 40px);
    max-width: 400px;
  }

  &__title {
    color: $text;
  }

  &__img {
    display: none;
    width: 100%;
    margin: 0;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)),
      linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
      linear-gradient(0deg, #ffffff, #ffffff);
  }

  &__button {
    display: block;
    margin-top: 40px;
  }

  @media (min-width: $medium) {
    height: 740px;
    background-image: url('./materials-bg.png');
    background-position: right center;

    &__content {
      font-size: 24px;
      line-height: 1.25;
      left: 60px;
    }
    
  }

  @media (min-width: $large) {
    background-position: right center;

    &__content {
      font-size: 36px;
    }
    &__title {
      font-size: 68px;
    }
  }
}
</style>
