import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    auth: '', // для гиперкомментса
    userData: {
      id: null,
      email: '',
      name: '',
      surname: '',
    },
    currentChat: {
      widgetId: null,
      chatId: ''
    },
    chatIsOpen: false,
    schedule: {
      common: [],
      personal: []
    },
    greenBalls: [],
    streamsCount: 9,
    pollsList: [],
    currentPollIds: {},
    currentPollIndex: null,
    speakers: [],
    meetingsSlots: [],
    reserveMeeting: {
      headers: {
        Authorization: 'Bearer fake-jwt-from-back'
      },
      data: {
        expertName: 'Йохан Вандерплаетсе',
        chosenSlotId: 'jhff3',
        chosenTime: '11:00 — 11:30'
      }
    }
  },
  mutations: {
    SET_CURRENT_POLL_INDEX(state, payload) {
      state.currentPollIndex = payload
    },
    SET_AUTH(state, payload) {
      state.auth = payload
    },
    SET_USER_DATA(state, payload) {
      state.userData = payload
    },
    SET_CURRENT_CHAT(state, payload) {
      state.currentChat = payload
    },
    TOGGLE_CHAT_IS_OPEN(state) {
      state.chatIsOpen = !state.chatIsOpen
    },
    SET_CHAT_IS_OPEN_FLAG(state, flag) {
      state.chatIsOpen = flag
    },
    SET_COMMON_SCHEDULE(state, payload) {
      state.schedule.common = payload
    },
    SET_PERSONAL_SCHEDULE(state, payload) {
      state.schedule.personal = payload
    },
    SET_STREAMS_COUNT(state, payload) {
      state.streamsCount = payload
    },
    SET_POLLS_LIST(state, payload) {
      state.pollsList = payload
    },
  },
  actions: {
    setCurrentPollIndex({ commit }, auth) {
      commit('SET_CURRENT_POLL_INDEX', auth)
    },
    setAuth({ commit }, auth) {
      commit('SET_AUTH', auth)
    },
    setUserData({ commit }, userData) {
      commit('SET_USER_DATA', userData)
    },
    clearUserData({ commit }) {
      commit('SET_USER_DATA', {})
      commit('SET_AUTH', '')
    },
    setCurrentChat({ commit }, { widgetId, chatId }) {
      commit('SET_CURRENT_CHAT', { widgetId, chatId })
    },
    toggleChat({ commit }) {
      commit('TOGGLE_CHAT_IS_OPEN')
    },
    setChatIsOpenFlag({ commit }, flag) {
      commit('SET_CHAT_IS_OPEN_FLAG', flag)
    },
    setCommonShedule({ commit }, arr) {
      commit('SET_COMMON_SCHEDULE', arr)
    },
    setPersonalShedule({ commit }, obj) {
      commit('SET_PERSONAL_SCHEDULE', obj)
    },
    setStreamsCount({ commit }, count) {
      commit('SET_STREAMS_COUNT', count)
    },
    setPollsList({ commit }, arr) {
      commit('SET_POLLS_LIST', arr)
    }
  },
  modules: {}
})
