<template lang="pug">

  .input-component(
    :class="[sizeModifier, errorMarker]"
  )
    input.input-component__input(
      :type="type"
      :name="name"
      required
      v-model="localValue"
    )

    .input-component__label {{label}}
    .input-component__error {{errorMsg}}

</template>

<script>
// import { mapActions, mapGetters } from "vuex";

export default {
  name: 'input-component',
  components: {},
  directives: {},
  filters: {},
  props: {
    type: {
      type: String,
      default: 'text',
      validator(value) {
        const inputTypes = ['text', 'password']
        return inputTypes.includes(value)
      }
    },
    name: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    errorMsg: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'm',
      validator(value) {
        const sizes = ['l', 'm']
        return sizes.includes(value)
      }
    }
  },
  data() {
    return {
      localValue: this.value
    }
  },
  computed: {
    // ...mapGetters("module", [
    //   "getter1",
    //   "getter2",
    // ])
    sizeModifier() {
      return `${this.$options.name}_size_${this.size}`
    },
    errorMarker() {
      return this.errorMsg ? 'error' : ''
    }
  },
  watch: {
    value() {
      this.localValue = this.value
    },
    localValue() {
      this.$emit('input', this.localValue)
    }
  },
  methods: {
    // ...mapActions("module", [
    //   "action1",
    //   "action2",
    // ])
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="scss">
@import '@/style/vars/vars.scss';

.input-component {
  position: relative;

  font-size: 16px;
  text-align: left;

  &__input {
    background: none;
    border: none;
    outline: none;

    width: 100%;
    height: 100%;
    padding: 28px 20px 12px;

    font: inherit;
    color: $text;

    background-color: $grey;
    border: 1px solid $border;
    border-radius: 5px;
  }

  &__label {
    position: absolute;
    top: 36%;
    transform: translateY(-50%);
    left: 20px;
    pointer-events: none;
    color: $text-secondary;
    transition-duration: 0.2s;
    line-height: 1.2;
  }

  &__input:focus,
  &__input:valid {
    background-color: $white;
  }

  &__input:focus ~ &__label,
  &__input:valid ~ &__label {
    top: 15px;
    font-size: 12px;
    line-height: 1;
  }

  &__error {
    min-height: 20px;
    padding: 2px 30px 2px;

    font-size: 14px;
    line-height: 1;
    color: $red;
  }

  &.error {
    .input-component__input {
      color: $red;
      border-color: $red;
    }
  }
}
</style>
