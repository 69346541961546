<template lang="pug">

  v-svg-icon.v-icon.v-icon_svg(
    v-if="isSVG && mask"
    :data="iconURL"
  )
  v-svg-icon.v-icon.v-icon_svg(
    v-else-if="isSVG && !mask"
    :data="iconURL"
    original
  )
  div.v-icon(
    v-else
    :style="iconStyle"
    :class="iconClass"
  )

</template>

<script>
export default {
  name: 'v-icon',
  props: {
    icon: String,
    dir: {
      type: String,
      default: 'assets/icons/'
    },
    mask: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {}
  },
  computed: {
    isSVG() {
      return /\.svg$/.test(this.icon)
    },
    fullPath() {
      return this.dir + this.icon
    },
    iconURL() {
      let requiredPath = false

      if (this.icon) {
        try {
          requiredPath = require('@/' + this.fullPath)
        } catch (error) {
          // if(process.env.NODE_ENV !== 'production'){
          return window.console.error(`Icon ${this.icon} not found in ${this.fullPath}`)
          // }
        }
      }

      return requiredPath
    },
    iconStyle() {
      let iconStyle

      if (this.iconURL) {
        if (this.mask) {
          iconStyle = {
            'mask-image': `url(${this.iconURL})`
          }
        } else {
          iconStyle = {
            'background-image': `url(${this.iconURL})`
          }
        }
      } else if (process.env.NODE_ENV !== 'production') {
        iconStyle = {
          border: '2px solid red',
          'background-image':
            'repeating-linear-gradient(-45deg, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0.75) 10%, rgba(255,220,0,0.75) 10%, rgba(255,220,0,0.75) 20%)'
        }
      }

      return iconStyle
    },
    iconClass() {
      return {
        'v-icon_masked': this.mask && !this.isSVG
      }
    }
  }
}
</script>

<style lang="scss">
.v-icon {
  width: 100%;
  height: 100%;

  background-color: currentColor;

  background-origin: border-box;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: center;
  background-position-x: center;

  &.v-icon_svg {
    background-color: transparent;
    fill: currentColor;
    display: block;
  }

  &.v-icon_masked {
    mask-origin: border-box;
    mask-repeat: no-repeat;
    mask-size: contain;
    // mask-position-y: center;
    -webkit-mask-position-y: center;
    // mask-position-x: center;
    -webkit-mask-position-x: center;
  }

  &_h {
    &_t {
      background-position-y: top;
      // mask-position-y: top;
      -webkit-mask-position-y: top;
    }
    &_c {
      background-position-y: center;
      // mask-position-y: center;
      -webkit-mask-position-y: center;
    }
    &_b {
      background-position-y: bottom;
      // mask-position-y: bottom;
      -webkit-mask-position-y: bottom;
    }
  }

  &_v {
    &_l {
      background-position-x: left;
      // mask-position-x: left;
      -webkit-mask-position-x: left;
    }
    &_c {
      background-position-x: center;
      // mask-position-x: center;
      -webkit-mask-position-x: center;
    }
    &_r {
      background-position-x: right;
      // mask-position-x: right;
      -webkit-mask-position-x: right;
    }
  }
}
</style>
