<template lang="pug">

  .quiz-block(v-if="isShown" :key="poll.id")
    .quiz-block__header
      .quiz-block__type Квиз

      .quiz-block__title.quiz-block__title_success(v-if="isSuccess") Вы ответили правильно

      .quiz-block__timer(v-if="!isMessageShown")
        v-simple-timer(
          :seconds="30"

          @timer-finished="$emit('start-hiding-timer')"
        )

    h3.title.quiz-block__question {{poll.name}}
    .quiz-block__note(v-if="isMultiple && isPoll") Выберите {{rightAnswersCount}} {{optionsWord}} ответа

    template(v-if="isPoll && poll.answers && poll.answers.length > 0")
      .quiz-block__options
        template(v-for="item in poll.answers")
          quiz-block-option.quiz-block__option(
            :key="item.id"
            :class="[activeModifier(item.id), errorModifier(item.id), disabledModifier]"

            @click.native="choseAnswer(item.id)"
          ) 
            span {{item.title}}

    .quiz-block__results(v-if="!isPoll")
      p(v-if="isMessageShown") {{resultMessage}}

</template>

<script>
// import { mapActions, mapGetters } from "vuex";
import axios from 'axios'
import QuizBlockOption from './QuizBlockOption'
import VSimpleTimer from '@/components/common/VSimpleTimer/VSimpleTimer'
import ButtonComponent from '@/components/UI/ButtonComponent/ButtonComponent'

export default {
  name: 'quiz-block',
  components: {
    QuizBlockOption,
    VSimpleTimer,
    ButtonComponent
  },
  directives: {},
  filters: {},
  props: {
    poll: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isPoll: true,
      isShown: true,
      chosedAnswers: [],
      isAnswerSending: false,
      isMessageShown: false,
      resultMessage: '',
      rightAnswers: [],
      wrongAnswers: [],
      isSuccess: false,
      hidingTimer: null
    }
  },
  computed: {
    // ...mapGetters("module", [
    //   "getter1",
    //   "getter2",
    // ])
    disabledModifier() {
      return this.isAnswerSending ? 'disabled' : ''
    },
    rightAnswersCount() {
      if (this.poll.rightAnswersId) {
        return this.poll.rightAnswersId.length
      }

      return 0
    },
    isMultiple() {
      return this.rightAnswersCount > 1
    },
    optionsWord() {
      return this.rightAnswersCount > 4 ? 'вариантов' : 'варианта'
    }
  },
  watch: {},
  methods: {
    // ...mapActions("module", [
    //   "action1",
    //   "action2",
    // ])
    activeModifier(id) {
      return this.rightAnswers.includes(id) || this.chosedAnswers.includes(id) ? 'active' : ''
    },
    errorModifier(id) {
      return this.wrongAnswers.includes(id) ? 'error' : ''
    },
    choseAnswer(id) {
      if (this.chosedAnswers.includes(id)) {
        return //Второй раз нажал на кнопку
      }

      this.chosedAnswers.push(id)

      // Запрещаем клики на опции
      this.isAnswerSending = true

      this.sendAnswers(this.chosedAnswers)
    },
    /**
     * Принимает массив с id ответов и текст (свой вариант или открытый опрос).
     * Если текста нет, приходит пустая строка
     * @param {Array} answers
     * @param {String} answerText
     */
    sendAnswers(answersIds) {
      axios
        .post(
          /* webpack-strip-block:removed */
          'https://live.proofix.tv/api-brooke-hotpoint/api/polls/save',
          {
            poll_id: this.poll.id,
            answer_id: answersIds[0]
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('jwt')}`,
              Accept: 'application/json'
            }
          }
        )
        .then(() => {
          if (this.rightAnswersCount === 0) {
            //Если правильных вариантов нет просто выходим
            this.$emit('start-hiding-timer')
            return
          }

          //Если есть правильныее варианты находим ошибочные
          this.rightAnswers.push(...this.poll.rightAnswersId)

          const wrong = this.chosedAnswers.filter(
            (item) => !this.poll.rightAnswersId.includes(item)
          )

          // Если ошибок нет, выводим пользователю сообщение, что он ответил правильно
          if (wrong.length === 0) {
            this.isSuccess = true
            this.$emit('start-hiding-timer')
            return
          }

          // Пушим ошибочные в массив, чтобы они отметились красным
          this.wrongAnswers.push(...wrong)

          this.$emit('start-hiding-timer') // Посылаем эмит, чтоб чере 5 секунд очистили (скрыли) квиз
        })
        .catch((error) => {
          console.log('Ошибка при отправке ответа квиз')
          console.error(error)
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$emit('refresh-token')
          }

          // Разрешаем клики на опции и чистим ответы, чтоб было понятно, что не сработало и можно было попытаться еще раз
          this.isAnswerSending = false
          this.chosedAnswers = []
        })
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="scss">
@import '@/style/vars/vars.scss';

.quiz-block {
  width: 100%;
  padding: 20px;

  background-color: $white;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.04);
  border-radius: 12px;

  min-height: 200px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 20px;

    line-height: 1;

    @media (min-width: $medium) {
      margin-bottom: 0;
    }
  }

  &__note {
    margin-top: -10px;
    margin-bottom: 15px;

    font-size: 18px;
    text-align: center;
    line-height: 1.25;
  }

  &__type {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 64px;
    height: 30px;

    color: $primary-dark;
    text-transform: uppercase;
    font-size: 14px;

    border: 1px solid $primary-dark;
    border-radius: 4px;
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    color: $text-secondary;

    &_success {
      color: $green;
    }
  }

  &__timer {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 68px;
    height: 30px;

    color: $white;
    text-transform: uppercase;
    font-size: 14px;

    background-color: $border-active;
    border-radius: 4px;

    @media (min-width: $medium) {
      width: 82px;
      height: 42px;
      font-size: 16px;
    }
  }

  &__question {
    text-align: center;

    @media (min-width: $medium) {
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 30px;
    }
  }

  &__option {
    width: 100%;
    margin-bottom: 10px;
  }

  &__results {
    p {
      font-weight: 500;
      font-size: 24px;
      line-height: 1.25;
      text-align: center;
    }
  }

  &__input-btn {
    margin-left: auto;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    margin-top: 20px;

    .quiz-block__question {
      width: 100%;
      font-size: 22px;
    }

    .quiz-block__stat {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  @media (min-width: $tablet) {
    &__row {
      .quiz-block__stat {
        width: 49%;
      }
    }
  }

  @media (min-width: $medium) {
    padding: 30px;
    border-radius: 4px;
  }

  @media (min-width: $large) {
    &__options {
      display: flex;
      flex-wrap: wrap;

      width: calc(100% + 20px);
      margin-right: -10px;
    }

    &__option {
      width: calc(50% - 20px);
      margin: 10px;
    }
  }
}
</style>
