<template lang="pug">

  .modal-auth
    modal-layout(
      @close-modal="$emit('close-modal')"
    )
      .modal-auth__content
        h2.title.modal-auth__title Войти в аккаунт

        input-component.modal-auth__input(
          label="Логин"
          :error-msg="userLoginErrorMsg"
          v-model="userLogin"
        )
        input-component.modal-auth__input(
          label="Пароль"
          type="password"
          :error-msg="passwordErrorMsg"
          v-model="password"
        )

        checkbox-component.modal-auth__checkbox(
          v-model="confirmShowName"
        )
          | Я согласен(-на) на отображение моего имени-фамилии в таблице участников акции 
          a(href="/innovation-summit-moscow-2021/actions_rule.pdf") «Копите “зелёные” баллы»*.

        .modal-auth__note *Если вы не согласны с отображением имени-фамилии, то применяется формат user XXX

        .modal-auth__backend-error {{backendError}}

        button-component.modal-auth__submit(
          @click.native="submit"
          @tap.native="submitIos"
          :disabled="submitDisabled"
        ) Войти

      .modal-auth__footer
        span.modal-auth__footer-reg
          | Нет аккаунта? 
          a.modal-auth__footer-link(
            href="https://events.se.com/ereg/newreg.php?eventid=200219451&language=rus"
          ) Зарегистрируйтесь

        a.modal-auth__footer-link.modal-auth__footer-link--right(
          href="https://events.se.com/ereg/modifyreg.php?eventid=200219451"
        ) Изменить профиль
        a.modal-auth__footer-link.modal-auth__footer-link--right(
          href="https://events.se.com/ereg/forgot.php?eventid=200219451&language=rus"
        ) Восстановить пароль

</template>

<script>
// import { mapActions, mapGetters } from "vuex";
import ModalLayout from '@/components/layout/ModalLayout/ModalLayout'
import InputComponent from '@/components/UI/InputComponent/InputComponent'
import CheckboxComponent from '@/components/UI/CheckboxComponent/CheckboxComponent'
import ButtonComponent from '@/components/UI/ButtonComponent/ButtonComponent'

export default {
  name: 'modal-auth',
  components: {
    ModalLayout,
    InputComponent,
    CheckboxComponent,
    ButtonComponent
  },
  directives: {},
  filters: {},
  props: {
    errorMsg: {
      type: String,
      default: ''
    },
    submitDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      userLogin: '',
      password: '',
      userLoginErrorMsg: '',
      passwordErrorMsg: '',
      confirmShowName: false,
      backendError: '',
      submitStart: false
    }
  },
  computed: {
    // ...mapGetters("module", [
    //   "getter1",
    //   "getter2",
    // ])
  },
  watch: {
    userLogin() {
      if (this.userLoginErrorMsg) {
        this.userLoginErrorMsg = ''
      }

      if (this.backendError) {
        this.backendError = ''
      }
    },
    password() {
      if (this.passwordErrorMsg) {
        this.passwordErrorMsg = ''
      }

      if (this.backendError) {
        this.backendError = ''
      }
    },
    errorMsg() {
      this.backendError = this.errorMsg
    }
  },
  methods: {
    // ...mapActions("module", [
    //   "action1",
    //   "action2",
    // ])
    submit() {
      this.submitStart = true

      let errorFlag = false
      let data = {}

      if (!this.userLogin) {
        this.userLoginErrorMsg = 'Введите логин'
        errorFlag = true
      }
      if (!this.password) {
        this.passwordErrorMsg = 'Введите пароль'
        errorFlag = true
      }

      if (errorFlag) {
        return
      }

      data = {
        login: this.userLogin,
        password: this.password,
        confirmShowName: this.confirmShowName
      }

      this.$emit('authorization', data)

      this.submitStart = false
    },
    submitIos() {
      if (this.submitStart) {
        return
      }

      this.submit()
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="scss">
@import '@/style/vars/vars.scss';

.modal-auth {
  text-align: left;

  &__content {
    width: 720px;
    max-width: 100%;

    border-bottom: 1px solid $border;
  }

  &__title {
    @media (max-width: $small) {
      font-size: 24px;
    }
  }

  &__checkbox {
    margin-top: 10px;
    margin-bottom: 10px;

    @media (max-width: $small) {
      font-size: 12px;
    }

    @media (min-width: $medium) {
      font-size: 14px;
    }
  }

  &__note {
    max-width: 706px;
    color: $text-secondary;
    font-size: 12px;

    // @media (max-width: $tablet) {
    //   display: none;
    // }

    @media (min-width: $medium) {
      font-size: 14px;
    }
  }

  &__submit {
    margin: 5px 0 20px auto;
  }

  &__backend-error {
    min-height: 25px;
    padding: 9px 30px 0;

    font-size: 16px;
    line-height: 1;
    color: $red;
    text-align: center;
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  &__footer-reg {
    margin-right: auto;
    margin-bottom: 10px;
  }

  &__footer-link {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    &--right {
      width: 100%;
    }
  }

  @media (min-width: $tablet) {
    &__footer {
      margin-top: 40px;
    }

    &__footer-link {
      &--right {
        width: auto;
        margin-left: 40px;
      }
    }
  }
}
</style>
