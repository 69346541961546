<template lang="pug">

  transition(name="t-fade" appear)
    .modal-layout(
      :class="{'no-overlay': noOverlay}"
    )
      .modal-layout__box
        button.modal-layout__close(
          type="button"
          @click="closePopup"
        )
          v-icon(icon="cross.svg")

        slot

</template>

<script>
// import { mapActions, mapGetters } from "vuex";
import ClickOutside from 'vue-click-outside'
import VIcon from '@/components/common/VIcon/VIcon'

export default {
  name: 'modal-layout',
  components: {
    VIcon
  },
  directives: {
    ClickOutside
  },
  filters: {},
  props: {
    noOverlay: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    // ...mapGetters("module", [
    //   "getter1",
    //   "getter2",
    // ])
  },
  watch: {},
  methods: {
    // ...mapActions("module", [
    //   "action1",
    //   "action2",
    // ])
    closePopup() {
      this.$emit('close-modal')
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="scss">
@import '@/style/vars/vars.scss';

.modal-layout {
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100vh;
  padding: 20px;

  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);

  z-index: 1000;

  &.no-overlay {
    background: none;
    backdrop-filter: unset;
  }

  &__box {
    width: min-content;
    max-width: 100%;
    max-height: 96vh;
    padding: 30px 20px;

    background-color: $white;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    overflow: auto;
  }

  &__close {
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    float: right;

    background: none;
    border: none;

    color: $border-active;

    cursor: pointer;

    &:hover,
    &:focus {
      color: $text;
    }

    path {
      transition: 0.2s;
    }
  }

  @media (min-width: $medium) {
    &__box {
      padding: 40px;
    }
  }
}
</style>
