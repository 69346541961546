<template lang="pug">

  nav.nav-main
    template(v-for="item in navLinks")
      router-link.nav-main__link(
        :key="item.title"
        :to="item.routeObject"
        :exact="item.exact"
      )
        .nav-main__icon
          v-icon(:icon="item.icon")

        .nav-main__title {{item.title}}

</template>

<script>
// import { mapActions, mapGetters } from "vuex";
import VIcon from '@/components/common/VIcon/VIcon'

export default {
  name: 'nav-main',
  components: {
    VIcon
  },
  directives: {},
  filters: {},
  props: {},
  data() {
    return {
      navLinks: [
        {
          title: 'Трансляция',
          icon: 'nav-main/se-play.png',
          routeObject: {
            name: 'Home'
          },
          exact: true
        },
        {
          title: 'Полезные материалы',
          icon: 'nav-main/se-mater.png',
          routeObject: {
            name: 'Materials'
          }
        }
      ]
    }
  },
  computed: {
    // ...mapGetters("module", [
    //   "getter1",
    //   "getter2",
    // ])
  },
  watch: {},
  methods: {
    // ...mapActions("module", [
    //   "action1",
    //   "action2",
    // ])
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="scss">
@import '@/style/vars/vars.scss';
.nav-main {
  position: relative;

  display: flex;
  padding: 0;

  background-color: #000;

  font-size: 14px;

  &__link {
    display: flex;
    align-items: center;

    padding: 15px 20px;

    font: inherit;
    text-align: left;
    color: $white;
    line-height: 1.1;

    background-color: #000;

    &:first-of-type {
      padding-left: 0;
    }
  }

  &__icon {
    flex-shrink: 0;
    width: 21px;
    height: 21px;
    margin-right: 10px;
  }

  @media (min-width: $medium) {
    &__link {
      padding: 0 20px;

      &:first-of-type {
        padding-left: 20px;
      }
    }

    &__icon {
      margin-right: 6px;
    }
  }
}
</style>
