<template lang="pug">

  div.v-simple-timer {{localSeconds | secondsToTime}}

</template>

<script>
// import { mapActions, mapGetters } from "vuex";

import secondsToTime from '@/filters/secondsToTime'

export default {
  name: 'v-simple-timer',
  components: {},
  directives: {},
  filters: {
    secondsToTime
  },
  props: {
    seconds: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      localSeconds: this.seconds,
      timerInterval: null
    }
  },
  computed: {
    // ...mapGetters("module", [
    //   "getter1",
    //   "getter2",
    // ])
  },
  watch: {
    seconds: {
      immediate: true,
      handler() {
        this.localSeconds = this.seconds

        if (this.seconds > 0 && this.timerInterval === null) {
          this.startTimer()
        } else if (this.seconds <= 0) {
          this.stopTimer()
        }
      }
    }
  },
  methods: {
    // ...mapActions("module", [
    //   "action1",
    //   "action2",
    // ])
    startTimer() {
      this.timerInterval = setInterval(() => {
        this.localSeconds -= 1

        if (this.localSeconds <= 0) {
          this.stopTimer()
        }
      }, 1000)
    },
    stopTimer() {
      clearInterval(this.timerInterval)
      this.timerInterval = null

      this.$emit('timer-finished')
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {
    clearInterval(this.timerInterval)
  },
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="scss">
@import '@/style/vars/vars.scss';

.v-simple-timer {
  display: inline-block;
}
</style>
