<template lang="pug">

  .support
    .container

</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'support',
  components: {},
  directives: {},
  filters: {},
  props: {},
  data() {
    return {
      // TODO Здесь должны быть данные чата поддержки
      chat: {
        widgetId: 106475,
        chatId: 'x19075'
      }
    }
  },
  computed: {
    // ...mapGetters("module", [
    //   "getter1",
    //   "getter2",
    // ])
  },
  watch: {},
  methods: {
    ...mapActions(['setCurrentChat', 'setChatIsOpenFlag'])
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    this.setCurrentChat({
      widgetId: this.chat.widgetId,
      chatId: this.chat.chatId
    })

    this.setChatIsOpenFlag(true)
  },
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {
    this.setChatIsOpenFlag(false)
  },
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="scss">
@import '@/style/vars/vars.scss';

.support {
  padding-top: 20px;

  &__intro {
    color: $text-secondary;
  }

  @media (min-width: $medium) {
    padding-top: 40px;

    &__title {
      margin-bottom: 30px;
    }

    &__intro {
      max-width: 565px;
      margin-left: auto;
      margin-right: auto;
      padding: 40px;
    }
  }
}
</style>
