<template lang="pug">

  div.home
    .container
      .home__header
        .home__logo
          img(src="../assets/img/main-logo.png" width="112" height="35")

        a.home__link.home__link_top(
          href="#"
          @click.prevent="isRegistration = !isRegistration"
        ) {{linkText}}

      .home__wrap
        .home__inner-wrap
          .title.home__title(v-if="!isRegistration || !regIsSucces")
            | Добро пожаловать!
            br
            | Презентация новых холодильников Hotpoint.
            br
            |Модельный ряд 2021г.
          .home__note(v-if="!isRegistration || !regIsSucces")
            | Чтобы присоединиться к просмотру презентации, нужно 
            | {{noteText}}

          registration-form.home__reg-form(
            v-if="isRegistration"

            :error-msg="regErrorMsg"
            :submit-disabled="regSubmitDisabled"
            :success="regIsSucces"

            @registration="registration"
            @show-auth="showAuth"
          )

          auth-form.home__auth-form(
            v-else

            :error-msg="authErrorMsg"
            :submit-disabled="authSubmitDisabled"

            @auth="authorization"
          )

          a.home__link.home__link_bottom(
            href="#"
            @click.prevent="isRegistration = !isRegistration"
          ) {{linkText}}

</template>

<script>
import { mapState, mapActions } from 'vuex'
import axios from 'axios'
import RegistrationForm from '@/components/forms/RegistrationForm/RegistrationForm'
import AuthForm from '@/components/forms/AuthForm/AuthForm'

export default {
  name: 'home',
  components: {
    RegistrationForm,
    AuthForm
  },
  directives: {},
  filters: {},
  props: {},
  data() {
    return {
      isRegistration: true,
      regErrorMsg: '',
      regSubmitDisabled: false,
      regIsSucces: false,
      authErrorMsg: '',
      authSubmitDisabled: false,
    }
  },
  computed: {
    // ...mapGetters("module", [
    //   "getter1",
    //   "getter2",
    // ])
    ...mapState(['userData']),
    linkText() {
      return this.isRegistration ? 'Войти в аккаунт' : 'Зарегистрироваться'
    },
    noteText() {
      return this.isRegistration ? 'зарегистрироваться.' : 'пройти авторизацию.'
    }
  },
  watch: {},
  methods: {
    ...mapActions(['clearUserData', 'setUserData', 'setAuth']),
    showAuth() {
      this.isRegistration = false
    },
    setCookie(key, value) {
      document.cookie = `${key}=${value};path=/;`
    },
    registration(data) {
      this.regErrorMsg = ''
      this.regSubmitDisabled = true

      axios
        .post('https://live.proofix.tv/api-brooke-hotpoint/api/auth/register', data)
        /* webpack-strip-block:removed */
        .then((response) => {
          if (response.data.access_token) {
            this.clearUserData()

            localStorage.setItem('jwt', response.data.access_token)
            this.setCookie('user_id', response.data.id)

            this.setUserData(response.data)

            this.regIsSucces = true
            this.setAuth(response.data.auth)

            return
          }

          // Неуспешно. Выводим текст ошибки
          if (response.data.error && response.data.error.length > 0) {
            this.regErrorMsg = response.data.error.join(' ')
          } else {
            this.regErrorMsg = 'Что-то пошло не так. Попробуйте позже'
          }

          console.log('Registration -> then -> not success')
          console.error(response)
        })
        .catch((error) => {
          console.log('Registration -> catch -> error')
          console.error(error)
          console.error(error.response)

          if (error.response.data.error && error.response.data.error.length > 0) {
            this.regErrorMsg = error.response.data.error.join(' ')
          } else {
            this.regErrorMsg = 'Что-то пошло не так. Попробуйте позже'
          }
        })
        .finally(() => (this.regSubmitDisabled = false))
    },
    authorization(data) {
      this.authErrorMsg = ''
      this.loginSubmitDisabled = true

      axios
        .post('https://live.proofix.tv/api-brooke-hotpoint/api/auth/login', data)
        /* webpack-strip-block:removed */
        .then((response) => {
          if (response.data.access_token) {
            this.clearUserData()

            localStorage.setItem('jwt', response.data.access_token)
            this.setCookie('user_id', response.data.id)

            this.setUserData(response.data)
            this.setAuth(response.data.auth)

            const today = Date.now()
            localStorage.setItem('tokent_refresh', today + ((response.data.expires_in - 1000) * 1000))

            this.$router.push({ name: 'Stream' })

            return
          }

          // Неуспешно. Выводим текст ошибки
          this.authErrorMsg = 'Логин или пароль не верный, попробуйте ещё раз'

          console.log('Authorization -> then -> not success')
          console.error(response)
        })
        .catch((error) => {
          console.log('Authorization -> catch -> error')
          console.error(error)
          console.error(error.response)

          this.authErrorMsg = 'Логин или пароль не верный, попробуйте ещё раз'
        })
        .finally(() => (this.loginSubmitDisabled = false))
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // Если авторизован, редирект на стрим
      if (vm.userData.id) {
        vm.$router.push({ name: 'Stream' })
      }
    })
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="scss">
@import '@/style/vars/vars.scss';

.home {
  // min-height: 100vh;
  color: $white;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 40px;

  &__link {
    &_top {
      display: none;
    }
    &_bottom {
      margin-top: 24px;
      display: inline-block;
    }
  }

  &__title {
    font-family: 'Hotpoint', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 125%;
    /* or 30px */

    text-align: center;
    letter-spacing: -0.01em;
    margin-bottom: 20px;
  }

  &__note {
    font-size: 16px;
    margin-bottom: 20px;
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
  }

  &__reg-form {
    width: 870px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  &__auth-form {
    width: 650px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .checkbox-component__label {
    color: $white;
    text-align: left;
  }
  .checkbox-component__marker {
    background-color: $white;
  }

  @media (min-width: $tablet) {
    &__header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
    }

    &__link {
      &_top {
        display: block;
      }
      &_bottom {
        display: none;
      }
    }

    &__wrap {
      max-width: 1460px;
      margin: 0 auto;
      padding: 24px;
      background: rgba(255, 255, 255, 0.03);
      /* glass_big */

      box-shadow: inset 0px 0px 68px rgba(255, 255, 255, 0.05), inset 0px 4px 4px rgba(255, 255, 255, 0.15);
      backdrop-filter: blur(86px);
      /* Note: backdrop-filter has minimal browser support */

      border-radius: 92px;
    }

    &__inner-wrap {
      padding: 40px 24px 35px;
      background: rgba(255, 255, 255, 0.03);
      /* glass_big */

      box-shadow: inset 0px 0px 68px rgba(255, 255, 255, 0.05), inset 0px 4px 4px rgba(255, 255, 255, 0.15);
      backdrop-filter: blur(86px);
      /* Note: backdrop-filter has minimal browser support */

      border-radius: 92px;

      min-height: 70vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  @media (min-width: $medium) {
    &__logo {
      img {
        width: 246px;
        height: 75px;
      }
    }
    &__title.title {
      font-size: 48px;
    }
    &__note,
    &__link {
      font-size: 24px;
    }
  }
}
</style>
