<template lang="pug">

  button.stream-nav-button(
    type="button"
    :class="{active: active}"
  ) {{roomName}}

</template>

<script>
// import { mapActions, mapGetters } from "vuex";

export default {
  name: 'stream-nav-button',
  components: {},
  directives: {},
  filters: {},
  props: {
    streamId: {
      type: Number,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      roomNamesList: [
        'Ампер',
        'Digital',
        'Эдисон',
        'Яблочков',
        'Тесла',
        'Сахаров',
        'Коперник',
        'Театр',
        'Пуанкаре'
      ]
    }
  },
  computed: {
    // ...mapGetters("module", [
    //   "getter1",
    //   "getter2",
    // ])
    // formatedId() {
    //   return this.streamId.toString().padStart(2, '0')
    // },
    roomName() {
      const index = (this.streamId % 9 || 9) - 1

      if (index > this.roomNamesList.length - 1) {
        return this.streamId.toString().padStart(2, '0')
      }

      return this.roomNamesList[index]
    }
  },
  watch: {},
  methods: {
    // ...mapActions("module", [
    //   "action1",
    //   "action2",
    // ])
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="scss">
@import '@/style/vars/vars.scss';

.stream-nav-button {
  $block: &;

  background: none;
  border: none;
  outline: none;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 11px 10px 9px;

  font-size: 16px;
  letter-spacing: 0.04em;
  line-height: 1.25;
  color: $primary;

  background-color: rgba($primary, 0.2);
  border-radius: 4px;

  cursor: pointer;

  transition: 0.2s;

  &:hover,
  &:focus {
    background-color: rgba($primary, 0.4);
  }

  &:active,
  &.active {
    color: $white;
    background-color: $primary;
  }

  &:disabled {
    color: $white;
    background-color: $border-active;
  }

  @media (min-width: $tablet) {
    padding: 15px;

    font-size: 18px;
  }
}
</style>
