<template lang="pug">

  .documents
    .container
      h1.documents__title.title Пользовательское соглашение

      .documents__content
        p Настоящее Соглашение определяет условия использования Пользователями материалов и сервисов сайта https://hotpoint-event.whirlpoolgroup.ru (далее — «Сайт»).
        h4 1.Общие условия
        p 1.1. Использование материалов и сервисов Сайта регулируется нормами действующего законодательства Российской Федерации.
        p 1.2. Настоящее Соглашение является публичной офертой. Получая доступ к материалам Сайта Пользователь считается присоединившимся к настоящему Соглашению. 
        p 1.3. Администрация Сайта вправе в любое время в одностороннем порядке изменять условия настоящего Соглашения. Такие изменения вступают в силу по истечении 3 (Трех) дней с момента размещения новой версии Соглашения на сайте. При несогласии Пользователя с внесенными изменениями он обязан отказаться от доступа к Сайту, прекратить использование материалов и сервисов Сайта.

        h4 2. Обязательства Пользователя
        p 
          | 2.1. Пользователь соглашается не предпринимать действий, которые могут рассматриваться как нарушающие российское законодательство или нормы международного права, в том числе в сфере 
          a(href="http://www.copyright.ru/intellectual/" target="_blank") интеллектуальной собственности, 
          a(href="http://www.copyright.ru/ru/documents/avtorskoe_pravo/avtorskie_prava/" target="_blank") авторских 
          |и/или 
          a(href="http://www.copyright.ru/ru/documents/avtorskoe_pravo/smegnie_prava/" target="_blank") смежных правах
          | , а также любых действий, которые приводят или могут привести к нарушению нормальной работы Сайта и сервисов Сайта.
        p 
          | 2.2. Использование материалов Сайта без согласия 
          a(href="http://www.copyright.ru/documents/avtorskoe_pravo/pravoobladateli/" target="_blank") правообладателей 
          | не допускается (статья 1270 Г.К РФ). Для правомерного использования материалов Сайта необходимо заключение 
          a(href="http://www.copyright.ru/ru/documents/avtorskoe_pravo/peredacha_avtorskih_prav/" target="_blank") лицензионных договоров 
          | (получение лицензий) от Правообладателей. 
        p 
          | 2.3. При 
          a(href="http://www.copyright.ru/library/zakonodatelstvo/gk_rf_obschee_zakonodatel/grazhdanskii_kodeks_RF_4_chast/glava_70__avtorskoe_pravo/#20" target="_blank") цитировании 
          | материалов Сайта, включая охраняемые авторские произведения, ссылка на Сайт обязательна (подпункт 1 пункта 1 статьи 1274 Г.К РФ).
        p 2.4. Комментарии и иные записи Пользователя на Сайте не должны вступать в противоречие с требованиями законодательства Российской Федерации и общепринятых норм морали и нравственности.
        p 2.5. Пользователь предупрежден о том, что Администрация Сайта не несет ответственности за посещение и использование им внешних ресурсов, ссылки на которые могут содержаться на сайте. 
        p 
          | 2.6. Пользователь согласен с тем, что Администрация Сайта не несет ответственности и не имеет прямых или косвенных обязательств перед Пользователем в связи с любыми возможными или возникшими потерями или убытками, связанными с любым содержанием Сайта, 
          a(href="http://www.copyright.ru/ru/documents/registraciy_avtorskih_prav/" target="_blank") регистрацией авторских прав 
          | и сведениями о такой регистрации, товарами или услугами, доступными на или полученными через внешние сайты или ресурсы либо иные контакты Пользователя, в которые он вступил, используя размещенную на Сайте информацию или ссылки на внешние ресурсы.
        p 2.7. Пользователь принимает положение о том, что все материалы и сервисы Сайта или любая их часть могут сопровождаться рекламой. Пользователь согласен с тем, что Администрация Сайта не несет какой-либо ответственности и не имеет каких-либо обязательств в связи с такой рекламой.

        h4 3. Прочие условия
        p 3.1. Все возможные споры, вытекающие из настоящего Соглашения или связанные с ним, подлежат разрешению в соответствии с действующим законодательством Российской Федерации. 
        p 3.2. Ничто в Соглашении не может пониматься как установление между Пользователем и Администрации Сайта агентских отношений, отношений товарищества, отношений по совместной деятельности, отношений личного найма, либо каких-то иных отношений, прямо не предусмотренных Соглашением.
        p 3.3. Признание судом какого-либо положения Соглашения недействительным или не подлежащим принудительному исполнению не влечет недействительности иных положений Соглашения.
        p 
          | 3.4. Бездействие со стороны Администрации Сайта в случае нарушения кем-либо из Пользователей положений Соглашения не лишает Администрацию Сайта права предпринять позднее соответствующие действия в защиту своих интересов и 
          a(href="http://www.copyright.ru/documents/zashita_avtorskih_prav/" target="_blank") защиту авторских прав 
          | на охраняемые в соответствии с законодательством материалы Сайта.

        h4 Пользователь подтверждает, что ознакомлен со всеми пунктами настоящего Соглашения и безусловно принимает их.

</template>

<script>

// import { mapActions, mapGetters } from "vuex";

export default {
  name: "documents-ps",
  components: {},
  directives: {},
  filters: {},
  props: {},
  data() {
    return {

    }
  },
  computed: {
    // ...mapGetters("module", [
    //   "getter1",
    //   "getter2",
    // ])
  },
  watch: {},
  methods: {
    // ...mapActions("module", [
    //   "action1",
    //   "action2",
    // ])
  },
  beforeCreate(){},
  created(){},
  beforeMount(){},
  mounted(){},
  beforeUpdate(){},
  updated(){},
  activated(){},
  deactivated(){},
  beforeDestroy(){},
  destroyed(){},
  errorCaptured(){}
}

</script>

<style lang="scss">
@import "@/style/vars/vars.scss";

.documents{
  padding-top: 20px;
  a {
    color: $blue;
  }

  &__content {
    max-width: 760px;
  }

  @media (min-width: $medium) {
    padding-top: 40px;
  }
}

</style>
