<template lang="pug">

  .header-main
    .header-main__top

      router-link.header-main__logo(
        :to="{name: 'Home'}"
      )
        img(src="./main-logo.png" alt="Hotpoint")

      .header-main__profile.profile
        template(v-if="isAuth")
          //- .profile__avatar
          //-   .profile__avatar-letter {{firstNameLetter}}

          .profile__first-name 
            | {{userData.name}} {{userData.surname}} 

          button.profile__exit(
            type="button"
            @click="$emit('logout')"
          ) Выйти

        template(v-else)
          router-link(
            :to="{name: 'Home'}"
          )
            button-component.profile__btn(
              size="xs"
              color="primary"
            ) Назад

      .header-main__nav
        nav-main


</template>

<script>
import { mapState } from 'vuex'
import VIcon from '@/components/common/VIcon/VIcon'
import ButtonComponent from '@/components/UI/ButtonComponent/ButtonComponent'
import NavMain from '@/components/layout/NavMain/NavMain'

export default {
  name: 'header-main',
  components: {
    VIcon,
    ButtonComponent,
    NavMain
  },
  directives: {},
  filters: {},
  props: {},
  data() {
    return {
      isMobileMenuOpen: false
    }
  },
  computed: {
    ...mapState(['userData']),
    isAuth() {
      return Boolean(this.userData.id)
    },
    firstNameLetter() {
      return this.userData.name ? this.userData.name.substr(0, 1) : ''
    }
  },
  watch: {},
  methods: {
    // ...mapActions("module", [
    //   "action1",
    //   "action2",
    // ])
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen
    },
    hideMobileMenu() {
      this.isMobileMenuOpen = false
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="scss">
@import '@/style/vars/vars.scss';

.header-main {
  position: relative;

  background-color: #000;
  color: $white;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);

  &__top {
    position: relative;
    z-index: 2;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;

    width: 100%;
    min-height: 40px;
    padding: 5px 10px 0;

    @media (min-width: $medium) {
      min-height: 50px;
      padding: 10px 20px;
    }
  }

  &__nav {
    width: 100%;

    @media (min-width: $medium) {
      order: 1;
      width: auto;
    }
  }

  &__logo {
    display: block;
    width: 76px;
    height: 20px;
    margin-right: auto;

    img {
      display: block;
      margin: 0;
    }

    @media (min-width: $medium) {
      width: 182px;
      height: 38px;
    }
  }

  &__ex-link {
    font-size: 14px;
  }

  &__top-links {
    display: none;

    @media (min-width: $medium) {
      display: block;
    }

    .header-main__ex-link {
      margin-left: 60px;
    }
  }

  &__profile {
    margin-left: 15px;

    @media (min-width: $medium) {
      margin-left: 70px;
      min-width: 120px;
      order: 2;
    }
  }
}

.profile {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &__avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;

    padding-top: 2px;

    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    line-height: 1;

    border-radius: 50%;
    background-color: rgba($primary, 0.2);

    overflow: hidden;

    @media (min-width: $medium) {
      display: none;
    }
  }

  &__first-name {
    // display: none;

    margin-left: 10px;
    line-height: 1;
    font-weight: 400;
    line-height: 1;

    @media (min-width: $medium) {
      display: block;
    }
  }

  &__exit {
    background: none;
    border: none;
    padding: 0 5px;

    margin-left: 10px;

    color: $border-active;

    transition: 0.2s;
    cursor: pointer;

    &:hover,
    &:focus {
      color: $white;
    }

    @media (min-width: $medium) {
      margin-left: 40px;
    }
  }
}
</style>
