<template lang="pug">

  .auth-form
    template(v-if="!success")
      input-component.auth-form__input(
        label="E-mail"
        name="email"
        :error-msg="userEmailErrorMsg"
        v-model="userEmail"
      )
      input-component.auth-form__input(
        label="Пароль / Password"
        name="password"
        type="password"
        :error-msg="passwordErrorMsg"
        v-model="password"
      )

      .auth-form__backend-error {{backendError}}

      button-component.auth-form__submit(
        @click.native="submit"
        @tap.native="submitIos"
        :disabled="submitDisabled"
      ) Продолжить

</template>

<script>

// import { mapActions, mapGetters } from "vuex";
import InputComponent from '@/components/UI/InputComponent/InputComponent'
import ButtonComponent from '@/components/UI/ButtonComponent/ButtonComponent'

export default {
  name: "auth-form",
  components: {
    InputComponent,
    ButtonComponent
  },
  directives: {},
  filters: {},
  props: {
    errorMsg: {
      type: String,
      default: ''
    },
    submitDisabled: {
      type: Boolean,
      default: false
    },
    success: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      userEmail: '',
      password: '',
      backendError: '',
      submitStart: false,
      userEmailErrorMsg: '',
      passwordErrorMsg: '',
    }
  },
  computed: {
    // ...mapGetters("module", [
    //   "getter1",
    //   "getter2",
    // ])
  },
  watch: {
    userEmail() {
      if (this.userEmailErrorMsg) {
        this.userEmailErrorMsg = ''
      }

      if (this.backendError) {
        this.backendError = ''
      }
    },
    password() {
      if (this.passwordErrorMsg) {
        this.passwordErrorMsg = ''
      }

      if (this.backendError) {
        this.backendError = ''
      }
    },
    errorMsg() {
      this.backendError = this.errorMsg
    }
  },
  methods: {
    // ...mapActions("module", [
    //   "action1",
    //   "action2",
    // ])
    submit() {
      this.submitStart = true

      let errorFlag = false
      let data = {}

      if (!this.userEmail) {
        this.userEmailErrorMsg = 'Введите email'
        errorFlag = true
      } else if (!emailValidator(this.userEmail)) {
        this.userEmailErrorMsg = 'Неверный формат email'
        errorFlag = true
      }

      if (!this.password) {
        this.passwordErrorMsg = 'Введите пароль'
        errorFlag = true
      }

      if (errorFlag) {
        return
      }

      data = {
        email: this.userEmail,
        password: this.password
      }

      this.$emit('auth', data)

      this.submitStart = false
    },
    submitIos() {
      if (this.submitStart) {
        return
      }

      this.submit()
    }
  },
  beforeCreate(){},
  created(){},
  beforeMount(){},
  mounted(){},
  beforeUpdate(){},
  updated(){},
  activated(){},
  deactivated(){},
  beforeDestroy(){},
  destroyed(){},
  errorCaptured(){}
}

function emailValidator(value){
  const re = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/; // eslint-disable-line
  return re.test(value);
}

</script>

<style lang="scss">
@import "@/style/vars/vars.scss";

.auth-form{
  position: relative;
  color: $white;

  &__backend-error {
    color: $red;
  }

  &__submit {
    width: 420px;
    max-width: 100%;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  &__success {
    text-align: center;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
  }
}

</style>
