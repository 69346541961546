<template lang="pug">

  button.quiz-block-option(
    type="button"
  )
    slot

</template>

<script>
// import { mapActions, mapGetters } from "vuex";

export default {
  name: 'quiz-block-option',
  components: {},
  directives: {},
  filters: {},
  props: {},
  data() {
    return {}
  },
  computed: {
    // ...mapGetters("module", [
    //   "getter1",
    //   "getter2",
    // ])
  },
  watch: {},
  methods: {
    // ...mapActions("module", [
    //   "action1",
    //   "action2",
    // ])
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="scss">
@import '@/style/vars/vars.scss';

.quiz-block-option {
  $block: &;

  background: none;
  border: none;
  outline: none;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 20px 11px;

  font-size: 16px;
  letter-spacing: 0.04em;
  line-height: 1.25;

  border-radius: 4px;

  cursor: pointer;

  transition: 0.2s;

  color: $white;
  background-color: $secondary;

  a {
    display: inline-block;
    width: 100%;
    height: 100%;
    color: inherit;
  }

  &:hover,
  &:focus {
    background-color: rgba($secondary, 0.9);
  }

  &:active,
  &.active,
  &:active {
    color: $white;
    background-color: rgba($green, 0.9);
  }

  &.error {
    color: $white;
    background-color: rgba($red, 0.9);
  }

  &.disabled {
    pointer-events: none;
  }

  @media (min-width: $medium) {
    padding: 18px 30px 20px;
    font-size: 18px;
  }
}
</style>
