<template lang="pug">

  .question-block
    .question-block__vote
      .question-block__title Задать вопрос спикеру

      .question-block__wrap(v-if="!isThanksShown")
        input-component.question-block__input(
          v-model="comment"
          label="Мой вопрос..."
        )

        button-component.question-block__input-btn(
          :class="[disabledModifier]"
          size="l"
          @click.native="sendQuestion"
        ) Отправить

      .question-block__thanks(v-else)
        .question-block__text.question-block__text--thanks Спасибо! Ваш вопрос отправлен

</template>

<script>
// import { mapState } from "vuex";
import axios from 'axios'
import InputComponent from '@/components/UI/InputComponent/InputComponent'
import ButtonComponent from '@/components/UI/ButtonComponent/ButtonComponent'

export default {
  name: 'question-block',
  components: {
    InputComponent,
    ButtonComponent
  },
  directives: {},
  filters: {},
  props: {},
  data() {
    return {
      comment: '',
      isAnswerSending: false,
      isThanksShown: false,
      hiddingInterval: null
    }
  },
  computed: {
    // ...mapState([
    //   'userData'
    // ]),
    disabledModifier() {
      return this.isAnswerSending ? 'disabled' : ''
    }
  },
  watch: {},
  methods: {
    // ...mapActions("module", [
    //   "action1",
    //   "action2",
    // ])
    sendQuestion() {
      if (!this.comment) {
        return
      }

      this.isAnswerSending = true

      const data = {
        question: this.comment
      }
      console.log(data)

      axios
        // .get('http://localhost:3004/question')
        .post(
          'https://live.proofix.tv/api-brooke-hotpoint/api/question',
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('jwt')}`,
              Accept: 'application/json'
            }
          }
        )
        .then((response) => {
          if (response.data.status === 'success') {
            this.isThanksShown = true

            this.hiddingInterval = setTimeout(() => {
              this.comment = ''
              this.isThanksShown = false
            }, 5000)
          } else {
            console.log('Что-то пошло не так при отправке вопроса')
            console.log(response)
          }
        })
        .finally(() => {
          this.isAnswerSending = false
        })
        .catch((error) => {
          console.log('Ошибка при отправке ответа вопроса')
          console.error(error)
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$emit('refresh-token')
          }
        })
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {
    clearTimeout(this.hiddingInterval)
  },
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="scss">
@import '@/style/vars/vars.scss';

.question-block {
  width: 100%;
  padding: 20px 0;

  &__thanks {
    margin-top: 60px;
  }

  &__text {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 15px;
    text-align: center;
    line-height: 1.25;
    font-weight: 500;

    &--thanks {
      margin-top: 20px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 28px;
    line-height: 1.2;

    &::after {
      content: "";
      display: block;
      background: #000;
      height: 2px;
      margin-top: 4px;
    }
  }

  &__input-btn {
    width: 360px;
    max-width: 100%;
    margin: auto;

    &.disabled {
      pointer-events: none;
    }
  }

  &__wrap {
    margin-top: 20px;
  }

  @media (min-width: $small) {
    &__text {
      font-size: 18px;
    }
  }

  @media (min-width: $medium) {
    padding: 30px 0;

    &__text {
      font-size: 20px;
    }

    &__title {
      font-size: 36px;
      display: flex;
      align-items: baseline;

      &::after {
        flex-grow: 1;
      }
    }

    &__wrap {
      display: flex;
      width: 100%;
      margin-top: 36px;
    }

    &__input {
      flex-grow: 1;
    }

    &__input-btn {
      max-width: 34%;
      margin: 0 0 0 20px;
    }
  }

  @media (min-width: $large) {
    &__text {
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
    }

    &__input-btn {
      margin-left: 40px;
    }
  }
}

.star-rating {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;

  margin-top: 20px;
  margin-bottom: 20px;

  &__label {
    width: 25px;
    height: 25px;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
  }

  &__input:checked ~ &__label,
  &__input:hover ~ &__label {
    path {
      fill: #ff9900;
    }
  }

  @media (min-width: $medium) {
    margin-bottom: 30px;
    margin-top: 30px;

    &__label {
      width: 35px;
      height: 35px;

      svg {
        width: 35px;
        height: 35px;
      }
    }
  }
}
</style>
