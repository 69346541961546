<script>
// TODO Ивенты окончания анимации
import { gsap } from 'gsap'

export default {
  functional: true,
  render(createElement, context) {
    /* Входные параметры: проверка и преобразование */
    /* Длительность перехода. По-умолчанию, 0.5 */
    const defaultDuration = 0.5
    const duration = Math.max(parseFloat(context.props.duration), 0) || defaultDuration
    /* Горизонтальный коллапс */
    const isHorizontal =
      'horizontal' in context.props && // Может быть все, что угодно,
      context.props.horizontal !== false // кроме явного запрета
    /* Вертикальный коллапс. По-умолчанию, если ничего не указано */
    const isVertical =
      ('vertical' in context.props || !isHorizontal) && context.props.vertical !== false // аналогично isHorizontal

    const directions = {}
    if (isHorizontal) {
      directions.width = 0
    }
    if (isVertical) {
      directions.height = 0
    }

    const element = context.props.group ? 'transition-group' : 'transition'

    const data = {
      props: {
        name: 't-collapse'
      },
      on: {
        /**
         * Анимация перехода при разворачивании
         * @param {HTMLElement} el DOM элемент тела спойлера
         * @param {Function} done Коллбэк окончания анимации
         */
        enter(el, done) {
          gsap.from(el, {
            ...directions,
            duration,
            onComplete: () => {
              if (isHorizontal) el.style.width = ''
              if (isVertical) el.style.height = ''
              done()
            }
          })
        },
        /**
         * Анимация перехода при сворачивании
         * @param {HTMLElement} el DOM элемент тела спойлера
         * @param {Function} done Коллбэк окончания анимации
         */
        leave(el, done) {
          gsap.to(el, {
            ...directions,
            duration,
            onComplete: done
          })
        }
      }
    }

    return createElement(element, data, context.children)
  }
}
</script>
