<template lang="pug">

  .footer-main
    ul.footer-main__nav
      li.footer-main__nav-item Контакты PR: Михайлов и Партнёры
      li.footer-main__nav-item Стратегические коммуникации
      li.footer-main__nav-item 
        a(href="mailto:whirlpool@m-p.ru" target="_blank") whirlpool@m-p.ru

    .footer-main__right
      ul.footer-main__social-list
        template(v-for="item in socialLinks")
          li.footer-main__social-item(:key="item.title")
            a.footer-main__social-link(
              :href="item.path"
              target="_blank"
            )
              span.visually-hidden {{item.title}}
              v-icon(
                :icon="item.icon"
                :mask="false"
              )

      a.footer-main__copyright(href="https://www.hotpoint.ru/" target="_blank") www.hotpoint.ru
      .footer-main__copyright ©2021 Hotpoint

</template>

<script>
// import { mapActions, mapGetters } from "vuex";
import VIcon from '@/components/common/VIcon/VIcon'

export default {
  name: 'footer-main',
  components: {
    VIcon
  },
  directives: {},
  filters: {},
  props: {},
  data() {
    return {
      // TODO Реальные ссылки
      socialLinks: [
        {
          title: 'Facebook',
          icon: 'footer-main/facebook.svg',
          path: 'https://www.facebook.com/HotpointRussia/'
        },
        {
          title: 'Youtube',
          icon: 'footer-main/youtube.svg',
          path: 'https://www.youtube.com/channel/UCbjJ4LayhetfRCwiSMCOl2w'
        },
        {
          title: 'Instagram',
          icon: 'footer-main/instagram.svg',
          path: 'https://www.instagram.com/hotpointrussia/'
        }
      ]
    }
  },
  computed: {
    // ...mapGetters("module", [
    //   "getter1",
    //   "getter2",
    // ])
  },
  watch: {},
  methods: {
    // ...mapActions("module", [
    //   "action1",
    //   "action2",
    // ])
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="scss">
@import '@/style/vars/vars.scss';

.footer-main {
  width: 100%;
  padding: 20px;

  color: $white;
  font-size: 16px;
  line-height: 1.65;
  text-align: left;

  background-color: $primary;

  &__nav-item {
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }
  }

  &__link {
    font: inherit;
    color: inherit;
    font-size: 14px;

    &:hover,
    &:focus {
      color: $border; //TODO Состояние ховера
    }
  }

  &__right {
    margin-top: 20px;
  }

  &__social-list {
    display: flex;
  }

  &__social-item {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__social-link {
    display: block;
    width: 24px;
    height: 24px;

    color: $primary !important;

    // TODO Hover, focus
  }

  &__copyright {
    display: block;
    margin-top: 12px;
  }

  @media (min-width: $tablet) {
    display: flex;
    justify-content: space-between;

    padding-left: 40px;
    padding-right: 40px;

    &__nav {
      height: 100%;
    }

    &__nav-item {
      margin: 0 40px 0 0;

      &:last-child {
        margin-top: auto;
      }
    }

    &__right {
      margin-top: 0;
    }

    &__copyright {
      text-align: right;
    }

    &__social-list {
      justify-content: flex-end;
    }
  }
}
</style>
