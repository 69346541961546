import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  /*{
    path: '/*',
    component: () => import('@/views/NotFound.vue')
  },*/
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/stream',
    name: 'Stream',
    component: () => import('@/views/Stream.vue')
  },
  {
    path: '/materials',
    name: 'Materials',
    component: () => import('@/views/Materials.vue')
  },
  {
    path: '/support',
    name: 'Support',
    component: () => import('@/views/Support.vue')
  },
  {
    path: '/pcdr',
    name: 'Placeholder',
    component: () => import('@/views/Placeholder.vue')
  },
  {
    path: '/user-agreement',
    name: 'PS',
    component: () => import('@/views/DocumentsPS.vue')
  },
  {
    path: '/confident',
    name: 'PC',
    component: () => import('@/views/DocumentsPC.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
