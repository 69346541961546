export default function (value) {
  let hours = Math.floor(value / 3600)
  let minutes = Math.floor((value % 3600) / 60)
  let seconds = Math.floor(value % 60)

  let formatedHours = ''

  if (hours) {
    formatedHours = `${hours.toString().padStart(2, '0')}:`
  }

  // window.console.log({minutes, value, x: value / 60, floor: Math.floor})

  return `${formatedHours}${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`
}
