<template lang="pug">

  .app#app(
    :class="{'is-home': isHome, 'is-placeholder': isPlaceholder}"
  )
    header-main.app__header(
      @logout="logout"
    )

    router-view.app__main(
      @refresh-token="refreshToken"
    )

    //- Пришлось вынести, потому что гиперкомментс не может одновремено динамичсекое и два виджета
    .container.support-container
      .support__main
        .support__main-right

          .support__footer(v-if="isSupportPage") 
            | Если у вас возникли технические сложности - напишите нам в чат.

          .support__chat
            hypercomments-widget(
              :is-static="isSupportPage"
            )

    router-link.app__support-btn(
      v-if="isHome"
      :to="{name: 'Support'}"
      :class="[supportBtnVisibillityModifier, supportBtnPositionModifier]"
    ) 
      .app__support-btn-icon
        v-icon(icon="help.svg")
      span Поддержка

    .app__sticky-btns(
      v-else
    )

      fixed-chat-button.app__chat-btn(
        v-show="isStreamPage && !isSupportPage && currentChat.widgetId && currentChat.chatId"

        :active="chatIsOpen"
        @click.native="toggleChat"
      )

      round-support-button

    footer-main.app__footer

    modal-cookie(
      v-if="isModalCookieShown"

      @close-modal="closeCookieModal"
    )

</template>

<script>
import { mapState, mapActions } from 'vuex'

import axios from 'axios'

import HeaderMain from '@/components/layout/HeaderMain/HeaderMain'
import FooterMain from '@/components/layout/FooterMain/FooterMain'
import RoundSupportButton from '@/components/UI/RoundSupportButton/RoundSupportButton'
import FixedChatButton from '@/components/UI/FixedChatButton/FixedChatButton'
import VIcon from '@/components/common/VIcon/VIcon'

export default {
  name: 'App',
  components: {
    HeaderMain,
    FooterMain,
    RoundSupportButton,
    FixedChatButton,
    VIcon,
    HypercommentsWidget: () =>
      import('@/components/common/HypercommentsWidget/HypercommentsWidget'),
    ModalCookie: () => import('@/components/modals/ModalCookie/ModalCookie')
  },
  data() {
    return {
      isModalCookieShown: true,
      refreshTimeout: null
    }
  },
  computed: {
    ...mapState(['userData', 'currentChat', 'chatIsOpen']),
    supportBtnVisibillityModifier() {
      return this.isSupportPage ? 'hidden' : ''
    },
    supportBtnPositionModifier() {
      return this.isStreamPage ? 'left' : ''
    },
    isHome() {
      return this.$route.name === 'Home'
    },
    isPlaceholder() {
      return this.$route.name === 'Placeholder'
    },
    isStreamPage() {
      return this.$route.name === 'Stream'
    },
    isSupportPage() {
      return this.$route.name === 'Support'
    }
  },
  watch: {},
  methods: {
    ...mapActions([
      'setAuth',
      'setUserData',
      'clearUserData',
      'toggleChat'
    ]),
    setCookie(key, value) {
      document.cookie = `${key}=${value};path=/;`
    },
    deleteCookie(cookieName) {
      let cookieDate = new Date() // Текущая дата и время
      cookieDate.setTime(cookieDate.getTime() - 1)
      document.cookie = cookieName += '=; expires=' + cookieDate.toGMTString()
    },
    getCookie(name) {
      let matches = document.cookie.match(
        // eslint-disable-next-line
        new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
      )
      return matches ? decodeURIComponent(matches[1]) : undefined
    },
    closeCookieModal() {
      this.setCookie('accept_cookie', true)
      this.isModalCookieShown = false
    },
    // TODO НАдо проверить работоспособность, чтоб мы в рекурсию не ушли случайно
    refreshToken() {
      if (!localStorage.getItem('jwt')) {
        return
      }

      axios
        // .get('http://localhost:3004/refresh')
        .post(
          'https://live.proofix.tv/api-brooke-hotpoint/api/auth/refresh', 
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('jwt')}`,
              Accept: 'application/json'
            }
          }
        )
        .then((response) => {
          if (response.data.access_token) {
            localStorage.setItem('jwt', response.data.access_token)

            const today = Date.now()
            localStorage.setItem('tokent_refresh', today + ((response.data.expires_in - 1000) * 1000))
          } else {
            console.error(response)
            this.logout()
          }
        })
        .catch((error) => {
          console.error(error)
          this.logout()
        })
    },
    checkAuth() {
      if (this.userData.id) {
        // Если в сторе есть данные, значит юзер авторизован.
        if (this.$route.name === 'Home') {
          this.redirectToFirstStream()
        }
      } else if (localStorage.getItem('jwt')) {
        this.authByToken(localStorage.getItem('jwt')).then(
          () => {
            if (this.$route.name === 'Home') {
              this.redirectToFirstStream()
            }
          },

          (error) => {
            console.error(error)
            // TODO Надо убедиться, что 401я падает сюда
            if (error.response && error.response.status === 401) {
              // TODO НАдо проверить работоспособность, чтоб мы в рекурсию не ушли случайно
              this.refreshToken()
            }
          }
        )
      }
    },
    authByToken(token) {
      return new Promise((resolve, reject) => {
        axios
          // .get('http://localhost:3004/authorization-token', 
          .get('https://live.proofix.tv/api-brooke-hotpoint/api/auth/me', 
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: 'application/json'
            }
          })
          .then((response) => {
            if (response.data.id) {
              this.setUserData(response.data)
              this.setAuth(response.data.auth)
              this.setCookie('user_id', response.data.id)

              resolve()
            } else {
              reject(response)
            }
          })
          .catch((error) => {
            console.error(error)
            if (error.response && error.response.status === 401) {
              // TODO НАдо проверить работоспособность, чтоб мы в рекурсию не ушли случайно
              this.refreshToken()
            }
          })
      })
    },
    logout() {
      // Всё чистим
      this.clearUserData()
      localStorage.removeItem('jwt')
      this.deleteCookie('user_id')
      localStorage.removeItem('tokent_refresh')

      // Если находится на странице, не дозволенной для незалогиненных, редирект на главную
      this.$router.push({ name: 'Home' })

      axios
        .post('https://live.proofix.tv/api-brooke-hotpoint/api/auth/logout',
          {}, 
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('jwt')}`,
              Accept: 'application/json' 
            }
          }
        )
        .then((response) => {
          if (response.data.message) {
            console.log(response.data.message)

            return
          }

          console.error(response)
        })
        .catch((error) => console.error(error))
    },
    redirectToFirstStream() {
      this.$router.push({ name: 'Stream' }) // Перенаправляем на трансляцию
      // this.$router.push({ name: 'Placeholder' }) // Перенаправляем на трансляцию
    }
  },
  created() {
    this.checkAuth()

    if (this.getCookie('accept_cookie')) {
      this.isModalCookieShown = false
    }
  },
  mounted() {
    this.refreshTimeout = setInterval(() => {
      const today = Date.now()

      if (localStorage.getItem('tokent_refresh') && today > +localStorage.getItem('tokent_refresh')) {
        this.refreshToken()
      }
    }, 10000)
  },
  beforeDestroy() {
    clearInterval(this.refreshTimeout)
    this.refreshTimeout = null
  }
}
</script>

<style lang="scss">
@import '@/style/vars/vars.scss';

@font-face {
  font-family: 'Hotpoint';
  src: local('Hotpoint Bold'), local('Hotpoint-Bold'),
    url('./style/fonts/Hotpoint-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hotpoint';
  src: local('Hotpoint Medium'), local('Hotpoint-Medium'),
    url('./style/fonts/Hotpoint-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hotpoint';
  src: local('Hotpoint'), local('Hotpoint-Regular'),
    url('./style/fonts/Hotpoint-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.app {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__header {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 6;
  }

  &__main {
    position: relative;

    flex-grow: 1;
  }

  &__footer {
    position: relative;
    z-index: 4;

    @media (min-width: $medium) {
      z-index: 6;
    }
  }

  &__support-btn {
    // position: sticky;
    // z-index: 4;
    // bottom: 40px;
    // left: 50%;
    // right: 40px;

    display: flex;
    align-items: center;
    padding: 8px 20px 6px;
    margin: 20px auto 40px auto;

    color: $white;
    font-size: 16px;

    border-top-right-radius: 20px;
    border-top-left-radius: 20px;

    &-icon {
      width: 18px;
      height: 18px;
      padding-top: 2px;
      margin-right: 8px;
    }

    &.left {
      margin-right: 80px;
    }

    &:hover,
    &:focus {
      color: $white;
    }

    @media (min-width: $medium) {
      margin: 20px 20px 40px auto;
      text-transform: none;
      z-index: 6;

      span {
        display: inline;
      }

      &.left {
        margin-right: 20px;
      }
    }
  }

  &.is-home {
    position: relative;
    background-color: #000;
    background-image: url('./assets/img/reg-mb-bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .app__header,
    .app__footer {
      display: none;
    }

    .support-container {
      display: none;
    }

    @media (min-width: $tablet) {
      background-image: url('./assets/img/reg-bg.png');
    }
    @media (min-width: $large) {
      background-image: url('./assets/img/feg-bg-lg.png');
    }
  }
  &.is-placeholder {
    .support-container {
      display: none;
    }
    .app__support-btn {
      position: absolute;
      z-index: 4;
      bottom: 40px;
      left: 50%;
      right: 40px;
    }
  }

  &__sticky-btns {
    position: sticky;
    right: 20px;
    bottom: 40px;
    display: flex;
    margin: 0 20px -20px auto;
    z-index: 10;

    @media (min-width: $medium) {
      margin-bottom: 20px;
    }
  }

  &__chat-btn {
    margin-right: 20px;
  }
}

.support {
  &__faq-item {
    margin-bottom: 40px;
  }

  &__footer {
    margin-bottom: 40px;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    span {
      white-space: nowrap;
    }
  }

  &__main {
    padding-bottom: 40px;
  }

  @media (min-width: $medium) {
    &__main {
      display: flex;
      width: 100%;
    }

    &__footer {
      font-size: 36px;
    }

    // &__main-left {
    //   width: calc(50% - 40px);
    //   margin-right: 50px;
    // }

    &__main-right {
      width: 100%;
    }
  }
}
</style>
