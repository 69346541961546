<template lang="pug">

  label.checkbox-component
    input.visually-hidden(
      type="checkbox" 
      v-model="localChecked"
    )
    .checkbox-component__marker
      .checkbox-component__tick
        v-icon(icon="checkmark.svg")

    .checkbox-component__label
      slot

</template>

<script>
// import { mapActions, mapGetters } from "vuex";
import VIcon from '@/components/common/VIcon/VIcon'

export default {
  name: 'checkbox-component',
  model: {
    prop: 'checked',
    event: 'change'
  },
  components: {
    VIcon
  },
  directives: {},
  filters: {},
  props: {
    checked: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'm',
      validator(value) {
        const sizes = ['l', 'm']
        return sizes.includes(value)
      }
    }
  },
  data() {
    return {
      localChecked: this.checked
    }
  },
  computed: {
    // ...mapGetters("module", [
    //   "getter1",
    //   "getter2",
    // ])
  },
  watch: {
    checked() {
      this.localChecked = this.checked
    },
    localChecked() {
      this.$emit('change', this.localChecked)
    }
  },
  methods: {
    // ...mapActions("module", [
    //   "action1",
    //   "action2",
    // ])
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="scss">
@import '@/style/vars/vars.scss';

.checkbox-component {
  $block: &;

  display: flex;
  align-items: center;

  &__marker {
    flex-shrink: 0;
    position: relative;
    width: 18px;
    height: 18px;

    border: 1px solid $border-active;
    border-radius: 5px;

    transition: 0.2s;
  }

  &__tick {
    position: absolute;
    top: 44%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 14px;
    height: 14px;

    color: $white;
    opacity: 0;

    transition: opacity 0.2s;

    svg {
      display: block;
    }
  }

  &__label {
    margin-left: 20px;
    color: $text-secondary;
    transition: color 0.2s;
  }

  input:checked ~ &__marker {
    border-color: $primary;
    background-color: $primary;
  }

  input:checked ~ &__marker &__tick {
    opacity: 1;
  }
}
</style>
