<template lang="pug">

  .voting-block
    .voting-block__vote(v-if="!isThanksShown")
      .voting-block__text Пожалуйста, оцените, по пятибальной шкале, насколько вам понравилась данная сессия, где 5 – очень понравилось, 1 – совсем не понравилось.
      .voting-block__text Просим также оставить отзыв о данной сессии. Ваше мнение очень важно для нас.

      .star-rating
        template(v-for="item in [5, 4, 3, 2, 1]")
          input.visually-hidden.star-rating__input(
            type="radio" 
            name="rating" 
            :value="item" 
            :id="`rate${item}`"

            @click="getRait(item)"
          )
          label.star-rating__label(:for="`rate${item}`")
            svg(width="25" height="25" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg")
              path(d="M8.1671 1.49688L10.0361 5.28434L10.1525 5.52007L10.4126 5.55788L14.5925 6.1654L11.5679 9.11338L11.3797 9.29686L11.4241 9.55595L12.1381 13.719L8.39981 11.7536L8.16714 11.6313L7.93447 11.7536L4.19588 13.7191L4.90987 9.55595L4.9543 9.29686L4.76605 9.11338L1.74149 6.1654L5.9214 5.55788L6.18151 5.52007L6.29785 5.28436L8.1671 1.49688Z" stroke="#FF9900")

      input-component.voting-block__input(
        v-model="comment"
        label="Отзыв"
      )

      button-component.voting-block__input-btn(
        :class="[disabledModifier]"
        size="s"
        @click.native="sendVoting"
      ) Отправить

    .voting-block__thanks(v-else)
      .voting-block__text.voting-block__text--thanks Спасибо! Ваш ответ отправлен

</template>

<script>
// import { mapActions, mapGetters } from "vuex";
import axios from 'axios'
import InputComponent from '@/components/UI/InputComponent/InputComponent'
import ButtonComponent from '@/components/UI/ButtonComponent/ButtonComponent'

export default {
  name: 'voting-block',
  components: {
    InputComponent,
    ButtonComponent
  },
  directives: {},
  filters: {},
  props: {},
  data() {
    return {
      rait: null,
      comment: '',
      isAnswerSending: false,
      isThanksShown: false
    }
  },
  computed: {
    // ...mapGetters("module", [
    //   "getter1",
    //   "getter2",
    // ])
    disabledModifier() {
      return this.isAnswerSending ? 'disabled' : ''
    }
  },
  watch: {},
  methods: {
    // ...mapActions("module", [
    //   "action1",
    //   "action2",
    // ])
    getRait(item) {
      this.rait = item
    },
    sendVoting() {
      if (!this.rait) {
        return
      }

      this.isAnswerSending = true

      const data = {
        rating: this.rait,
        text: this.comment,
        stream_id: this.$route.params.id
      }

      axios
        // .post('http://localhost:3004/voting-send',
        .post(
          'https://live.proofix.tv/api-innovation-summit-moscow-2021_back/api/poll/evaluation/store',
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
          }
        )
        .then((response) => {
          if (response.data.status === 'success') {
            this.isThanksShown = true

            this.$emit('start-hiding-timer') // Посылаем эмит, чтоб чере 5 секунд очистили (скрыли) квиз
          } else {
            console.log('Что-то пошло не так при отправке оценки')
            console.log(response)
          }
        })
        .finally(() => {
          this.isAnswerSending = false
          //TODO Что делаем, если ошибка? все равно скрываем через 5 сек?
        })
        .catch((error) => console.error(error))
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="scss">
@import '@/style/vars/vars.scss';

.voting-block {
  width: 100%;
  padding: 20px;

  background-color: $white;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.04);
  border-radius: 12px;

  &__text {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 15px;
    text-align: center;
    line-height: 1.25;
    font-weight: 500;

    &--thanks {
      margin-top: 20px;
    }
  }

  &__input-btn {
    margin: auto;

    &.disabled {
      pointer-events: none;
    }
  }

  @media (min-width: $small) {
    &__text {
      font-size: 18px;
    }
  }

  @media (min-width: $medium) {
    padding: 30px;
    border-radius: 4px;

    &__text {
      font-size: 20px;
    }
  }

  @media (min-width: $large) {
    &__text {
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.star-rating {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;

  margin-top: 20px;
  margin-bottom: 20px;

  &__label {
    width: 25px;
    height: 25px;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
  }

  &__input:checked ~ &__label,
  &__input:hover ~ &__label {
    path {
      fill: #ff9900;
    }
  }

  @media (min-width: $medium) {
    margin-bottom: 30px;
    margin-top: 30px;

    &__label {
      width: 35px;
      height: 35px;

      svg {
        width: 35px;
        height: 35px;
      }
    }
  }
}
</style>
