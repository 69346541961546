<template lang="pug">

  .quiz-statistic
    template(v-for="item in Object.entries(statistic)")
      .quiz-statistic__item
        .quiz-statistic__key {{splitText(item[0])}} ({{item[1]}})
        .quiz-statistic__bar
          .quiz-statistic__fill(
            :style="{width: getWidth(item[1])}"
          )
        

</template>

<script>
// import { mapActions, mapGetters } from "vuex";

export default {
  name: 'quiz-statistic',
  components: {},
  directives: {},
  filters: {},
  props: {
    statistic: {
      type: Object,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {
    // ...mapGetters("module", [
    //   "getter1",
    //   "getter2",
    // ])
    maxValue() {
      return Math.max(...Object.values(this.statistic))
    }
  },
  watch: {},
  methods: {
    // ...mapActions("module", [
    //   "action1",
    //   "action2",
    // ])
    getWidth(value) {
      return `${(value * 100) / this.maxValue}%`
    },
    splitText(text) {
      return `${text.substr(0, 80)}`
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="scss">
@import '@/style/vars/vars.scss';

.quiz-statistic {
  display: flex;
  flex-direction: column;

  &__item {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__key {
    flex-shrink: 0;
    width: 100px;
    margin-right: 10px;

    line-height: 1.25;
    text-align: right;
  }

  &__bar {
    flex-grow: 1;

    display: flex;
  }

  &__fill {
    height: 30px;
    background-color: $primary;
  }

  @media (min-width: $tablet) {
    &__key {
      width: 150px;
    }
  }

  @media (min-width: $large) {
    &__key {
      width: 150px;
    }
  }
}
</style>
