<template lang="pug">

  .registration-form
    template(v-if="!success")
      input-component.registration-form__input(
        label="E-mail"
        name="email"
        :error-msg="userEmailErrorMsg"
        v-model="userEmail"
      )
      input-component.registration-form__input(
        label="Имя / Name"
        name="name"
        :error-msg="userNameErrorMsg"
        v-model="userName"
      )
      input-component.registration-form__input(
        label="Фамилия / Surname"
        name="surname"
        :error-msg="userSurnameErrorMsg"
        v-model="userSurname"
      )
      input-component.registration-form__input(
        label="Пароль / Password"
        name="password"
        type="password"
        :error-msg="passwordErrorMsg"
        v-model="password"
      )
      input-component.registration-form__input(
        label="Подтвердить пароль / Сonfirm password"
        type="password"
        name="password_confirmation"
        :error-msg="confirmPasswordErrorMsg"
        v-model="confirmPassword"
      )

      checkbox-component.registration-form__checkbox(
        v-model="confirmCheckbox"
      )
        | Я принимаю условия 
        router-link(:to="{name: 'PS'}" target="_blank") Пользовательского соглашения 
        | и даю свое согласие на обработку моей персональной информации на условиях, определенных 
        router-link(:to="{name: 'PC'}" target="_blank") Политикой конфиденциальности.

      .registration-form__backend-error {{backendError}}

      button-component.registration-form__submit(
        @click.native="submit"
        @tap.native="submitIos"
        :disabled="submitDisabled"
      ) Зарегистрироваться

    template(v-else)
      .registration-form__success
        img(src="./done.png")
        .registration-form__title Поздравляем, Вы зарегистрированы!
        button-component.registration-form__submit(
          @click.native="auth"
          @tap.native="authIos"
        ) Войти

</template>

<script>

// import { mapActions, mapGetters } from "vuex";
import InputComponent from '@/components/UI/InputComponent/InputComponent'
import CheckboxComponent from '@/components/UI/CheckboxComponent/CheckboxComponent'
import ButtonComponent from '@/components/UI/ButtonComponent/ButtonComponent'

export default {
  name: "registration-form",
  components: {
    InputComponent,
    CheckboxComponent,
    ButtonComponent
  },
  directives: {},
  filters: {},
  props: {
    errorMsg: {
      type: String,
      default: ''
    },
    submitDisabled: {
      type: Boolean,
      default: false
    },
    success: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      confirmCheckbox: false,
      userEmail: '',
      userName: '',
      userSurname: '',
      password: '',
      confirmPassword: '',
      backendError: '',
      submitStart: false,
      userEmailErrorMsg: '',
      userNameErrorMsg: '',
      userSurnameErrorMsg: '',
      passwordErrorMsg: '',
      confirmPasswordErrorMsg: ''
    }
  },
  computed: {
    // ...mapGetters("module", [
    //   "getter1",
    //   "getter2",
    // ])
  },
  watch: {
    userEmail() {
      if (this.userEmailErrorMsg) {
        this.userEmailErrorMsg = ''
      }

      if (this.backendError) {
        this.backendError = ''
      }
    },
    userName() {
      if (this.userNameErrorMsg) {
        this.userNameErrorMsg = ''
      }

      if (this.backendError) {
        this.backendError = ''
      }
    },
    userSurname() {
      if (this.userSurnameErrorMsg) {
        this.userSurnameErrorMsg = ''
      }

      if (this.backendError) {
        this.backendError = ''
      }
    },
    password() {
      if (this.passwordErrorMsg) {
        this.passwordErrorMsg = ''
      }

      if (this.backendError) {
        this.backendError = ''
      }
    },
    confirmPassword() {
      if (this.confirmPasswordErrorMsg) {
        this.confirmPasswordErrorMsg = ''
      }

      if (this.backendError) {
        this.backendError = ''
      }
    },
    errorMsg() {
      this.backendError = this.errorMsg
    },
    confirmCheckbox() {
      this.backendError = ''
    }
  },
  methods: {
    // ...mapActions("module", [
    //   "action1",
    //   "action2",
    // ])
    submit() {
      this.submitStart = true

      let errorFlag = false
      let data = {}

      if(!this.confirmCheckbox) {
        this.backendError = 'Примите Политику конфиденциальности'
        errorFlag = true
      }

      if (!this.userEmail) {
        this.userEmailErrorMsg = 'Введите email'
        errorFlag = true
      } else if (!emailValidator(this.userEmail)) {
        this.userEmailErrorMsg = 'Неверный формат email'
        errorFlag = true
      }
      if (!this.userName) {
        this.userNameErrorMsg = 'Введите Имя'
        errorFlag = true
      }
      if (!this.userSurname) {
        this.userSurnameErrorMsg = 'Введите Фамилию'
        errorFlag = true
      }
      if (!this.password) {
        this.passwordErrorMsg = 'Введите пароль'
        errorFlag = true
      }
      if (!this.confirmPassword) {
        this.confirmPasswordErrorMsg = 'Подтвердите пароль'
        errorFlag = true
      }

      if (this.password && this.confirmPassword) {
        if (this.password !== this.confirmPassword) {
          this.confirmPasswordErrorMsg = 'Пароли не совпадают'
          errorFlag = true
        }
      }

      if (errorFlag) {
        return
      }

      data = {
        email: this.userEmail,
        name: this.userName,
        surname: this.userSurname,
        password: this.password,
        password_confirmation: this.confirmPassword
      }

      this.$emit('registration', data)

      this.submitStart = false
    },
    submitIos() {
      if (this.submitStart) {
        return
      }

      this.submit()
    },
    auth() {
      this.submitStart = true
      this.$emit('show-auth')
      this.submitStart = false
    },
    authIos() {
      if (this.submitStart) {
        return
      }

      this.auth()
    }
  },
  beforeCreate(){},
  created(){},
  beforeMount(){},
  mounted(){},
  beforeUpdate(){},
  updated(){},
  activated(){},
  deactivated(){},
  beforeDestroy(){},
  destroyed(){},
  errorCaptured(){}
}

function emailValidator(value){
  const re = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/; // eslint-disable-line
  return re.test(value);
}

</script>

<style lang="scss">
@import "@/style/vars/vars.scss";

.registration-form{
  position: relative;
  color: $white;

  &__backend-error {
    color: $red;
  }

  &__submit {
    width: 420px;
    max-width: 100%;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  &__checkbox {
    a {
      text-decoration: underline;
    }
  }

  &__success {
    text-align: center;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: $medium) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;

    &__input {
      width: calc(50% - 15px);

      &:first-of-type {
        width: 100%;
      }
    }

    &__backend-error {
      width: 100%;
    }
  }
}

</style>
