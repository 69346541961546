<template lang="pug">

  transition(name="t-slide-left" appear)
    .hypercomments-widget__chat-container(
      v-show="chatIsOpen"

      :class="[sideModifier, heightModifier, staticModifier]"
    ) 

      .hypercomments-widget__header(v-if="!isStatic")
        .hypercomments-widget__header-icon
          v-icon(
            icon="chat-blue.svg"
            :mask="false"
          )

        | Чат

      .hypercomments-widget#hypercomments_widget(ref="hypercommentsWidget")
  

</template>

<script>
import { mapState, mapActions } from 'vuex'
import VIcon from '@/components/common/VIcon/VIcon'

export default {
  name: 'hypercomments-widget',
  components: {
    VIcon
  },
  directives: {},
  filters: {},
  props: {
    isStatic: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      heightModifier: '',
      resizeListner: null,
      scrollListner: null
    }
  },
  computed: {
    ...mapState(['currentChat', 'chatIsOpen', 'auth']),
    sideModifier() {
      return this.windowWidth >= 1240 ? 'desktop' : ''
    },
    staticModifier() {
      return this.isStatic ? 'static' : ''
    }
  },
  watch: {
    'currentChat.chatId': 'updateHypercomments',
    'currentChat.widgetId': {
      immediate: true,
      handler() {
        if ('HC_LOAD_INIT' in window) {
          console.log('чат уже инциализирован')
          return
        }

        if (!this.currentChat.widgetId || !this.currentChat.chatId) {
          console.log('не хватает данных для чата')
          return
        }

        this.initHypercomments()
      }
    }
  },
  methods: {
    ...mapActions(['setChatIsOpenFlag']),
    updateHypercomments() {
      console.log('chat update start')
      if (!('HC_LOAD_INIT' in window)) {
        console.log('Чат не инициализирован')
        console.log('chat update return')
        this.initHypercomments()
        return
      }

      this.$refs.hypercommentsWidget.innerHTML = ''
      var _hcp = {}
      _hcp.widget_id = this.currentChat.widgetId
      _hcp.xid = this.currentChat.chatId
      // _hcp.auth = this.auth
      window.HC.widget('Stream', _hcp)
    },
    initHypercomments() {
      setTimeout(() => {
        console.log('chat init start')
        console.log(this.currentChat.chatId, this.currentChat.widgetId)
        const self = this
        console.log(self.auth)

        window._hcwp = window._hcwp || []
        window._hcwp.push({
          widget: 'Stream',
          widget_id: self.currentChat.widgetId,
          xid: self.currentChat.chatId,
          auth: self.auth
        })
        ;(function () {
          if ('HC_LOAD_INIT' in window) return
          window.HC_LOAD_INIT = true
          var lang = (
            navigator.language ||
            navigator.systemLanguage ||
            navigator.userLanguage ||
            'ru'
          )
            .substr(0, 2)
            .toLowerCase()
          var hcc = document.createElement('script')
          hcc.type = 'text/javascript'
          hcc.async = true
          hcc.src =
            ('https:' == document.location.protocol ? 'https' : 'http') +
            '://w.hypercomments.com/widget/hc/' +
            self.currentChat.widgetId +
            '/' +
            lang +
            '/widget.js'
          var s = document.getElementsByTagName('script')[0]
          s.parentNode.insertBefore(hcc, s.nextSibling)
        })()
      }, 1000)
    },
    checkAndShowChat() {
      if (this.windowWidth >= 1240 && this.$route.name === 'Stream' && !this.chatIsOpen) {
        this.setChatIsOpenFlag(true)
      }
    },
    isVisible(el) {
      const windowHeight = window.innerHeight
      const rect = el.getBoundingClientRect()

      return !(windowHeight <= rect.bottom || rect.bottom <= 0)
    },
    modifyHeight() {
      if (this.isVisible(document.querySelector('.app__footer'))) {
        if (!this.heightModifier) {
          this.heightModifier = 'short-desktop'
        }
      } else if (this.heightModifier) {
        this.heightModifier = ''
      }
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    this.checkAndShowChat()

    this.resizeListner = window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth

      this.checkAndShowChat()
    })

    this.scrollListner = window.addEventListener('scroll', this.modifyHeight)
  },
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeListner)
    window.removeEventListener('scroll', this.scrollListner)
  },
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="scss">
@import '@/style/vars/vars.scss';

.hypercomments-widget {
  $block: &;

  border: 1px solid $border;
  border-radius: 12px 12px 0px 0px;

  &__chat-container {
    position: fixed;
    top: 30px;
    right: 0;

    width: 100%;
    height: 100%;
    padding: 60px 20px 40px 20px;

    background-color: $white;

    overflow: auto;

    z-index: 5;

    &.desktop {
      // position: absolute;
      top: 50px;
      max-width: calc(30% - 20px);
      height: calc(100% - 50px);
      padding-top: 20px;

      border-radius: 0;

      &.short-desktop:not(.static) {
        height: calc(100% - 211px);
      }

      .hypercomments-widget__header {
        display: flex;
      }
    }

    &.static {
      position: static;
      padding: 0;

      max-width: unset;
    }
  }

  &__header {
    display: none;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 700;
  }

  &__header-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    color: transparent;
  }

  @media (min-width: $tablet) {
    &__chat-container {
      max-width: 400px;
    }
  }
}
</style>
