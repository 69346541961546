<template lang="pug">

  .main-page-layout
    .container
      .main-page-layout__left-column
        .main-page-layout__intro
          h2.title Добро пожаловать на презентацию новой модели холодильника Hotpoint в формате онлайн!  
          p Мы расскажем о кардинальных изменениях в потребительских предпочтениях, продемонстрируем результаты исследования возможностей моделей Hotpoint в области защиты от бактерий и вирусов и представим абсолютно новый холодильник! 

        .main-page-layout__translation
          video-component.main-page-layout__video(
            :stream-src="currentStreamSrc"
            @show-auth="$emit('show-auth')"
          )

        //- .main-page-layout__question
        //-   question-block(
        //-     @refresh-token="$emit('refresh-token')"
        //-   )

        .interactive-anchor(ref="anchor")
        .main-page-layout__open-quiz(v-if="!isPoll && !isResults && !quizTimeIsOver && pollsList.length > 0 && !pollIsDone && !pollIsShown")
          button-component.main-page-layout__open-quiz-btn(
            size="l"
            @click.native="openQuiz"
          ) Хочу пройти квиз
          .main-page-layout__open-quiz-note Квиз доступен до 18:00 21.05.21 (Мск)

        .main-page-layout__poll(v-if="isPoll")
          quiz-block(
            :key="poll.id"
            :poll="poll"

            @start-hiding-timer="$emit('start-poll-hiding-timer')"
            @refresh-token="$emit('refresh-token')"
          )

        .main-page-layout__thanks(v-if="showThanks") Спасибо за участие!

        .main-page-layout__results(v-if="isResults")
          .title Победители
          .main-page-layout__results-list
            template(v-for="item in winners")
              .main-page-layout__results-item(:key="item.user.id") {{item.user.email}}
      

</template>

<script>
import { mapState } from 'vuex'
// import axios from 'axios'
import VideoComponent from '@/components/blocks/VideoComponent/VideoComponent'
import ButtonComponent from '@/components/UI/ButtonComponent/ButtonComponent'

export default {
  name: 'main-page-layout',
  components: {
    VideoComponent,
    ButtonComponent,
    QuizBlock: () => import('@/components/blocks/QuizBlock/QuizBlock'),
    VotingBlock: () => import('@/components/blocks/VotingBlock/VotingBlock'),
    QuestionBlock: () => import('@/components/blocks/QuestionBlock/QuestionBlock')
  },
  directives: {},
  filters: {},
  props: {
    activeStreamId: {
      type: String,
      default: '1'
    },
    streamSrc: {
      type: String,
      default: ''
    },
    streamEnSrc: {
      type: String,
      default: ''
    },
    poll: {
      type: Object,
      default: () => {}
    },
    isVoting: {
      type: Boolean,
      default: false
    },
    pollIsShown: {
      type: Boolean,
      default: false
    },
    showThanks: {
      type: Boolean,
      default: false
    },
    isResults: {
      type: Boolean,
      default: false
    },
    winners: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      currentStreamSrc: this.streamSrc,
      quizTimeIsOver: false,
      quizTimeCheckInterval: null
    }
  },
  computed: {
    ...mapState(['pollsList']),
    isPoll() {
      if (this.poll) {
        return Object.keys(this.poll).length > 0
      }
      return false
    },
    pollIsDone() {
      if (!this.getCookie('done_poll_index')) {
        return false
      }
      return +this.getCookie('done_poll_index') >= this.pollsList.length
    }
  },
  watch: {
    streamSrc() {
      this.currentStreamSrc = this.streamSrc
    },
    isVoting() {
      if (this.isVoting) {
        this.scrollToInteractive()
      }
    },
    isPoll() {
      if (this.isPoll) {
        this.scrollToInteractive()
      }
    }
  },
  methods: {
    // ...mapActions(['authByToken'])
    getCookie(name) {
      let matches = document.cookie.match(
        // eslint-disable-next-line
        new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
      )
      return matches ? decodeURIComponent(matches[1]) : undefined
    },
    scrollToInteractive() {
      const anchor = this.$refs.anchor

      this.$smoothScroll({
        scrollTo: anchor,
        updateHistory: false
      })
    },
    openQuiz() {
      this.$emit('open-quiz')
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    if (new Date() >= new Date('May 21 2021 18:00:00 GMT+0300')) {
      this.quizTimeIsOver = true
      return
    }

    this.quizTimeCheckInterval = setInterval(() => {
      if (new Date() >= new Date('May 21 2021 18:00:00 GMT+0300')) {
        this.quizTimeIsOver = true
        clearInterval(this.quizTimeCheckInterval)
        this.quizTimeCheckInterval = null
      }
    }, 1000)
  },
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {
    clearInterval(this.quizTimeCheckInterval)
    this.quizTimeCheckInterval = null
  },
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="scss">
@import '@/style/vars/vars.scss';

.main-page-layout {
  padding-top: 20px;

  &__intro {
    position: relative;
    margin-bottom: 60px;

    @media (min-width: $small) {
      margin-bottom: 0;
      p + p {
        padding-right: 150px;
      }
    }
  }

  &__translation {
    margin-top: 30px;
  }

  &__stream-nav {
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    width: calc(100% + 8px);
    margin-left: -4px;

    &-item {
      flex-grow: 1;
      margin: 10px 4px 0;

      // &:last-child {
      //   margin-right: 0;
      //   // flex-grow: 0;
      // }
    }

    @media (min-width: 380px) {
      &-item {
        flex-grow: 0;
      }
    }

    @media (min-width: $small) {
      width: calc(100% + 10px);
      margin-left: -5px;

      &-item {
        margin: 10px 5px 0;
      }
    }

    @media (min-width: 1010px) and (max-width: $medium) {
      &-item {
        flex-grow: 1;
      }
    }

    @media (min-width: $medium) {
      width: calc(100% + 16px);
      margin-left: -8px;

      &-item {
        margin: 16px 8px 0;
      }
    }

    @media (min-width: $large) {
      &-item {
        flex-grow: 1;
      }
    }
  }

  &__schedule {
    margin-top: 30px;

    @media (min-width: $medium) {
      margin-top: 80px;
    }
  }

  &__langs {
    position: absolute;
    bottom: -40px;
    right: 0;
    display: flex;

    @media (min-width: $small) {
      bottom: 0;
      right: 0;
    }
  }

  &__lang-btn {
    margin-left: 10px;

    &.active {
      color: $white;
      background-color: $primary;
    }
  }

  &__poll,
  &__voting {
    margin-top: 30px;
  }

  &__results {
    width: 100%;
    padding: 20px;

    background-color: $white;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.04);
    border-radius: 12px;

    min-height: 200px;

    overflow: hidden;

    .title {
      text-align: center;
    }

    &-list {
      margin-top: 25px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      // @media (min-width: $tablet) {
      //   flex-direction: row;
      //   justify-content: space-between;
      //   flex-wrap: wrap;
      // }
    }

    &-item {
      width: auto;
      font-weight: 700;
      font-size: 18px;
      margin-bottom: 20px;
      word-break: break-word;
      &:first-of-type {
        color: $gold;
        font-size: 22px;
        margin-bottom: 17px;
      }

      @media (min-width: $tablet) {
        // width: 50%;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;

        &:first-of-type {
          width: 100%;
        }
      }
    }

    @media (min-width: $medium) {
      padding: 30px;
      border-radius: 4px;
    }
  }
  
  &__open-quiz {
    margin-top: 40px;
    text-align: center;

    &-btn {
      margin: 0 auto 20px;
    }
    &-note {
      font-size: 22px;
    }
  }

  &__thanks {
    margin: 70px auto 0;
    width: 340px;
    max-width: 100%;
    color: $white;
    font-size: 24px;
    padding: 16px 30px 20px;
    background-color: $green;
    text-align: center;
  }

  @media (min-width: $tablet) {
    &__translation {
      margin-top: 40px;
    }

    &__open-quiz {
      margin-top: 60px;
    }
  }

  @media (min-width: $medium) {
    .container {
      position: relative;
      display: flex;
      height: 100%;
    }

    &__left-column {
      width: 1120px;
      max-width: 70%;
    }

    &__poll,
    &__voting {
      margin-top: 40px;
    }
  }
}
</style>
