<template lang="pug">

  .materials
    .container
      h2.materials__title.title Полезные материалы

      .materials__container
        materials-link.materials__link

</template>

<script>
import { mapState } from 'vuex'
import MaterialsLink from '@/components/blocks/MaterialsLink/MaterialsLink'

export default {
  name: 'materials',
  components: {
    MaterialsLink
  },
  directives: {},
  filters: {},
  props: {},
  data() {
    return {}
  },
  computed: {
    ...mapState(['userData'])
  },
  watch: {},
  methods: {
    // ...mapActions("module", [
    //   "action1",
    //   "action2",
    // ])
  },
  // beforeRouteEnter(to, from, next) {
  //   next((vm) => {
  //     if (!vm.userData.id) {
  //       vm.$router.push({ name: 'Home' })
  //     } else {
  //       vm.$router.push({ name: 'Placeholder' })
  //     }
  //   })
  // },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="scss">
@import '@/style/vars/vars.scss';

.materials {
  padding-top: 20px;
  padding-bottom: 20px;

  &__link {
    margin-bottom: 40px;
  }

  @media (min-width: $medium) {
    padding-top: 40px;
    padding-bottom: 40px;

    &__container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__title {
      margin-bottom: 30px;
    }

    &__link {
      width: 100%;
    }

    // &__padlet {
    //   width: calc(50% - 20px);
    //   // height: 100%;
    //   // min-height: 740px;
    // }
  }
}
</style>
